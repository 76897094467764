import { Api, Equity } from '@/modules/common/types/api';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { Decimal } from 'decimal.js';
import { omit } from 'lodash';

/**
 * Normalize functions used by api.service and mocks:
 *
 * a) Convert strings to Decimal
 * b) Convert strings to Date
 * c) Ensure string literals are asserted
 */

export function normalizeContractDetails(
  item: Api.TermLoans.Raw.ContractDetailsResponse
): Api.TermLoans.ContractDetails {
  const equities = item.equities.map((equity) => ({
    ...equity,
    lastClosePrice: new Decimal(equity.lastClosePrice),
  }));

  const equitiesByCusip = equities.reduce(
    (acc, equity) => {
      acc[equity.cusip] = equity;
      return acc;
    },
    {} as Record<string, Equity>
  );

  return {
    ...normalizeContractSummary(item),
    equities,
    items: item.items.map((contractItem) => ({
      ...omit(contractItem, ['totalValue', 'cusip', 'ticker']),
      totalValue: new Decimal(contractItem.totalValue),
      equity: equitiesByCusip[contractItem.cusip],
    })),
  };
}

export function normalizeContractSummary(
  item: Api.TermLoans.Raw.ContractSummary
): Api.TermLoans.ContractSummary {
  return {
    ...item,
    rate: new Decimal(item.rate),
    notionalValue: new Decimal(item.notionalValue),
    currentValue: new Decimal(item.currentValue),
    startDate: new Date(item.startDate),
    createdAt: new Date(item.createdAt),
    endDate: new Date(item.endDate),
    threshold: new Decimal(item.threshold),
    maxEquityPercent: new Decimal(item.maxEquityPercent),
    independentAmountRate: new Decimal(item.independentAmountRate),
    roundingRule: item.roundingRule as RoundingRule,
    availableActions: normalizeAvailableContractActions(item),
    equities: item.equities.map((equity) => ({
      ...equity,
      lastClosePrice: new Decimal(equity.lastClosePrice),
    })),
  };
}

/**
 * Injects "availableActions" into the object based on the current contract state
 */
export function normalizeAvailableContractActions(
  contract: Api.TermLoans.Raw.ContractSummary
): Api.TermLoans.ContractSummary['availableActions'] {
  const actions: Api.TermLoans.ContractSummary['availableActions'] = {};

  if (contract.status === 'draft' && contract.isCreator) {
    actions.edit = true;
    actions.cancelDraft = true;
  }

  if (contract.status === 'proposed' && contract.isCreator) {
    actions.edit = true;
    actions.cancelProposed = true;
  }

  if (contract.status === 'proposed' && !contract.isCreator) {
    actions.accept = true;
    actions.reject = true;
  }

  if (contract.status === 'proposed' && !contract.isCreator && contract.side === 'BORROWER') {
    actions.accept = true;
    actions.reject = true;
    actions.manageLoans = true;
  }

  if (contract.status === 'accepted' && contract.side === 'borrower') {
    actions.manageLoans = true;
  }

  if (contract.status === 'accepted' && contract.side === 'lender') {
    actions.view = true;
  }

  if (contract.status === 'active' && contract.side === 'lender') {
    actions.view = true;
  }

  if (contract.status === 'active' && contract.side === 'borrower') {
    actions.manageLoans = true;
  }

  // @TODO: implement "completed" status

  return actions;
}

export function normalizeTermLoansUploadSecuritiesResponse(
  data: Api.TermLoans.Raw.SecuritiesUploadResponse
): Api.TermLoans.SecuritiesUploadResponse {
  return {
    ...data,
    instruments: data.instruments.map((instrument) => ({
      ...instrument,
      lastClosePrice: new Decimal(instrument.lastClosePrice),
    })),
  };
}

export function normalizeTermLoansUploadQuantitiesResponse(
  data: Api.TermLoans.Raw.QuantitiesUploadResponse
): Api.TermLoans.QuantitiesUploadResponse {
  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      instrument: {
        ...item.instrument,
        lastClosePrice: new Decimal(item.instrument.lastClosePrice),
      },
    })),
  };
}
