<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ isEditMode ? 'Edit' : 'Create' }} <format-side :side="side" /> Order
          </span>
          <v-spacer />
          <span v-if="order" class="text--primary headline-2">
            {{ order.orderRef }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="!showSummary">
            <v-row class="mt-n4" dense>
              <v-col cols="6">
                <simple-equity-search
                  v-model="equity"
                  :autofocus="equity === null"
                  :disabled="isEditMode"
                  :error-messages="errorMsgs['equity']"
                  label="Security"
                  @input="$v.equity.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="orderType"
                  disabled
                  item-text="text"
                  item-value="value"
                  :items="orderTypeOptions"
                  label="Order Type"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <numeric-input
                  v-model="quantity"
                  :autofocus="equity !== null"
                  data-test="quantity"
                  :error-messages="errorMsgs['quantity']"
                  :label="`Quantity${order && filled > 0 ? ` (executed: ${filled})` : ''}`"
                  :min="0"
                  :step="100"
                  type="integer"
                  @change="$v.quantity.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <numeric-input
                  v-model="rate"
                  :error-messages="errorMsgs['rate']"
                  label="Rate"
                  :precision="ratePrecision"
                  :step="0.25"
                  suffix="%"
                  type="decimal"
                  @change="$v.rate.$touch()"
                />
              </v-col>
            </v-row>

            <v-expansion-panels v-model="expandedPanel" data-test="advanced" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">Advanced Fields</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="6">
                      <v-select
                        v-model="timeInForceType"
                        :disabled="isEditMode"
                        item-text="text"
                        item-value="value"
                        :items="timeInForceOptions"
                        label="Time in Force"
                      />
                    </v-col>
                    <v-col cols="6">
                      <multiple-chip-counterparty-selector
                        :bilateral="settlementType === 'BILATERAL'"
                        :counterparties.sync="counterparties"
                        :disabled="!clientConfig.orderbookCounterpartyChoiceEnabled"
                        :exclude-company-id="loginState.user.companyID"
                        label="Eligible Counterparties"
                        side="ALL"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="6">
                      <settlement-type-select
                        v-model="settlementType"
                        :disabled="!clientConfig.orderbookSettlementTypeChoiceEnabled"
                        label="Settlement Type"
                      />
                    </v-col>
                    <v-col cols="6">
                      <numeric-input
                        v-model="minQuantity"
                        data-test="minQuantity"
                        :disabled="!clientConfig.orderbookMinQuantityEditable"
                        :error-messages="errorMsgs['minQuantity']"
                        label="Min. Quantity"
                        :max="quantity === null ? 1 : quantity - filled"
                        :min="1"
                        :step="100"
                        type="integer"
                        @change="$v.minQuantity.$touch()"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="6">
                      <numeric-input
                        v-model="independentAmountRate"
                        :disabled="isEditMode"
                        :error-messages="errorMsgs['independentAmountRate']"
                        label="Independent Amount"
                        :max="maxIa"
                        :min="0"
                        :precision="iaPrecision"
                        :step="1"
                        suffix="%"
                        type="decimal"
                        @blur="$v.independentAmountRate.$touch()"
                        @change="$v.independentAmountRate.$touch()"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="roundingRule"
                        :disabled="isEditMode"
                        item-text="text"
                        item-value="value"
                        :items="roundingRuleOptions"
                        label="Rounding Rule"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row v-if="errorMsgs.apiErrors.length || errorMsgs.form.length">
              <v-col class="px-1 col-6 offset-3">
                <div class="v-alert v-alert--dense text--primary text-body-2 text-center error">
                  {{ errorMsgs.apiErrors.join('\n') || errorMsgs.form.join('\n') }}
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container
            v-if="showSummary"
            v-shortkey="['enter']"
            @shortkey="
              submitForm(
                isEditMode ? (order?.routingStatus === 'ROUTED' ? 'ROUTED' : 'UNROUTED') : 'ROUTED'
              )
            "
          >
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <marketplace-order-summary
                  hide-routing
                  :order="{
                    ...formFields,
                    createdAt: order ? order.createdAt : undefined,
                    orderRef: order ? order.orderRef : undefined,
                  }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div class="v-alert v-alert--dense text--primary text-body-2 text-center error">
                  {{ errorMsgs.apiErrors.join('\n') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <template v-if="!showSummary">
              <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
              <v-btn color="primary" type="submit" @click="goToSummary()">
                {{ isEditMode ? 'Edit' : 'Create' }}</v-btn
              >
            </template>

            <template v-if="showSummary">
              <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="goBack()">
                Back
              </v-btn>
              <aurora-btn-dropdown
                v-if="order"
                color="primary"
                data-test="save-button"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                :main-text="order.routingStatus === 'ROUTED' ? 'Save active' : 'Save inactive'"
                split
                timeframe="createLoans"
                @click="submitForm(order ? order.routingStatus : 'ROUTED')"
              >
                <v-list ref="route-actions" dense>
                  <v-list-item data-test="save-routed-menu-item" @click="submitForm('ROUTED')">
                    <v-list-item-title>Save active</v-list-item-title>
                  </v-list-item>
                  <v-list-item data-test="save-unrouted-menu-item" @click="submitForm('UNROUTED')">
                    <v-list-item-title>Save inactive</v-list-item-title>
                  </v-list-item>
                </v-list>
              </aurora-btn-dropdown>
              <aurora-btn-dropdown
                v-else
                color="primary"
                data-test="create-button"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                main-text="Create active"
                split
                timeframe="createLoans"
                @click="submitForm('ROUTED')"
              >
                <v-list ref="route-actions" dense>
                  <v-list-item data-test="create-routed-menu-item" @click="submitForm('ROUTED')">
                    <v-list-item-title>Create active</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    data-test="create-unrouted-menu-item"
                    @click="submitForm('UNROUTED')"
                  >
                    <v-list-item-title>Create inactive</v-list-item-title>
                  </v-list-item>
                </v-list>
              </aurora-btn-dropdown>
            </template>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import {
  IA_PRECISION,
  MAX_IA,
  MAX_RATE,
  RATE_PRECISION,
} from '@/modules/common/constants/precision';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { decimal, integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import i18n from '@/localisation/i18n';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import SettlementTypeSelect from '@/modules/common/components/SettlementTypeSelect.vue';
import { Api, Equity } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import MarketplaceOrderSummary from '@/modules/marketplace/components/MarketplaceOrderSummary.vue';
import { timeInForceOptions } from '@/modules/marketplace/helpers/marketplace';
import {
  OrderSide,
  OrderType,
  SettlementType,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import { RoundingRule, roundingRuleOptions } from '@/modules/sec-lending/helpers/contract-details';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { LoginState } from '@/store/store';
import { BorrowRequest } from '@/utils/api/borrower';
import { ApiError } from '@/utils/errors';
import { calculateOrderNotional, formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import { ClientConfig } from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import Decimal from 'decimal.js';
import { Watch } from 'vue-property-decorator';
import MultipleChipCounterpartySelector from '@/modules/user-accounts/components/MultipleChipCounterpartySelector.vue';

interface FormErrors {
  apiErrors: string[];
  equity: string[];
  quantity: string[];
  rate: string[];
  minQuantity: string[];
  independentAmountRate: string[];
  form: string[];
}

const defaultMinQuantity = 100;

@Component({
  props: {
    order: Object as PropType<Api.Marketplace.Order>,
    newOrderSide: String as PropType<OrderSide>,
    newOrderEquity: Object as PropType<Equity>,
  },
  components: {
    MultipleChipCounterpartySelector,
    SettlementTypeSelect,
    SimpleEquitySearch,
    BtnDropdown,
    MarketplaceOrderSummary,
  },
  computed: {
    ...mapState(['marketplaceOrderDetails', 'loginState', 'clientConfig']),
  },
  mixins: [validationMixin],
  validations: function (this: MarketplaceOrderFormDialog) {
    return {
      equity: { required, tradable: (value: Equity) => !value?.cannotTradeMessage },
      quantity: {
        integer,
        required,
        minValue: minValue(1),
      },
      rate: {
        decimal,
        required,
        notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
        minValue: minValue(-MAX_RATE),
        maxValue: maxValue(MAX_RATE),
      },
      minQuantity: {
        integer,
        minValue: minValue(1),
        maxValue: maxValue(this.quantity == null ? 1 : this.quantity - this.filled),
      },
      independentAmountRate: {
        decimal,
        notGreaterThanPrecision: notGreaterThanPrecision(IA_PRECISION),
        minValue: minValue(0),
        maxValue: maxValue(MAX_IA),
      },
      form: {
        changed: () => {
          return (
            !this.$v.$anyDirty ||
            Object.keys(this.formFields).some((field) => {
              let previousValue = this.order?.[field];
              let newValue = this[field];
              if (field === 'counterparties') {
                // edge case: user can add/remove counterparties,
                // reordering them only while keeping the same counterparties
                previousValue = previousValue.sort((a, b) => a.companyId - b.companyId);
                newValue = newValue.sort((a, b) => a.companyId - b.companyId);
              }
              return JSON.stringify(previousValue) !== JSON.stringify(newValue);
            })
          );
        },
      },
    };
  },
})
export default class MarketplaceOrderFormDialog extends mixins(validationMixin) {
  // props
  protected readonly clientConfig!: ClientConfig;
  protected readonly order?: Api.Marketplace.Order;
  protected readonly newOrderSide?: 'BORROWER' | 'LENDER';
  protected readonly newOrderEquity?: Equity;

  // consts
  protected readonly defaultMinQuantity = defaultMinQuantity;

  // store state
  protected marketplaceOrderDetails!: Api.Marketplace.OrderDetails;

  // store methods
  protected submitOrder!: (req: BorrowRequest) => Promise<void>;
  protected loginState!: NonNullableAll<LoginState>;

  protected counterpartyOptions: CompanyInfo[] = [];

  protected side: OrderSide = 'borrower';
  protected equity: Equity | null = null;
  protected formStatus: DialogFormStatus = 'idle';
  protected showSummary = false;
  protected apiErrors: string[] = [];
  protected orderType: OrderType = 'LIMIT';
  protected timeInForceType: TimeInForceType = 'DAY';
  protected rate: Decimal | null = null;
  protected ratePrecision = RATE_PRECISION;
  protected maxIa = MAX_IA;
  protected iaPrecision = IA_PRECISION;
  protected quantity: number | null = null;
  protected minQuantity: number | null = defaultMinQuantity;
  protected filled = 0;
  protected counterparties: CompanyInfo[] = [];
  protected independentAmountRate: Decimal = new Decimal(0);
  protected roundingRule: RoundingRule = RoundingRule.NoRounding;
  protected isAnonymous = false;
  protected settlementType: SettlementType = 'NSCC';
  protected expandedPanel: number | null = null;

  protected minQuantityTouched = false;

  protected orderTypeOptions: Array<{ value: OrderType; text: string }> = [
    { value: 'LIMIT', text: 'Limit' },
    { value: 'MARKET', text: 'Market' },
  ];

  protected timeInForceOptions = timeInForceOptions;

  protected roundingRuleOptions: Array<{ value: RoundingRule; text: string }> =
    roundingRuleOptions();

  protected get isEditMode(): boolean {
    return typeof this.order === 'object';
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      equity: [],
      quantity: [],
      rate: [],
      minQuantity: [],
      independentAmountRate: [],
      form: [],
    };

    if (!this.$v.form.changed) {
      errors.form.push('Order is already in the requested state.');
    }

    if (this.$v.equity.$dirty) {
      if (!this.$v.equity.required) errors.equity.push('please select a security.');
      if (!this.$v.equity.tradable)
        errors.equity.push(i18n.t(this.equity!.cannotTradeMessage!) as string);
    }

    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
      if (this.filled > 0 && this.quantity && this.quantity <= this.filled)
        errors.quantity.push('must be greater than the executed quantity');
    }

    if (this.$v.rate.$dirty) {
      if (!this.$v.rate.required) errors.rate.push('rate is required.');
      if (this.rate && !this.$v.rate.notGreaterThanPrecision)
        errors.rate.push(`rate must not be more than ${RATE_PRECISION} decimal places.`);
      if (!this.$v.rate.minValue) {
        errors.rate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}%.`);
      }
      if (!this.$v.rate.maxValue) {
        errors.rate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}%.`);
      }
    }

    if (this.$v.minQuantity.$dirty) {
      if (!this.$v.minQuantity.integer)
        errors.minQuantity.push('please enter a valid min. quantity.');
      if (!this.$v.minQuantity.minValue) errors.minQuantity.push('must be greater than 0.');
      if (this.quantity && !this.$v.minQuantity.maxValue)
        errors.minQuantity.push(
          `must be less than or equal to remaining quantity (${this.prettyNumber(
            this.quantity - this.filled
          )}).`
        );
    }

    if (this.$v.independentAmountRate.$dirty) {
      if (!this.$v.independentAmountRate.notGreaterThanPrecision) {
        errors.independentAmountRate.push(
          `IA Rate must not be more than ${IA_PRECISION} decimal places.`
        );
      }
      if (!this.$v.independentAmountRate.minValue) {
        errors.independentAmountRate.push(`IA Rate cannot be negative.`);
      }
      if (!this.$v.independentAmountRate.maxValue) {
        errors.independentAmountRate.push(`IA Rate cannot be more than ${MAX_IA}.`);
      }
    }

    return errors;
  }

  protected get equityDescription(): string {
    return this.equity ? `${this.equity.ticker} [${this.equity.cusip}]` : '';
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get formFields(): Omit<
    Api.Marketplace.Order,
    | 'orderRef'
    | 'status'
    | 'createdAt'
    | 'routingStatus'
    | 'avgExecutionRate'
    | 'openQuantity'
    | 'totalValue'
  > {
    return {
      side: this.side,
      quantity: this.quantity as number,
      minQuantity: this.minQuantity === null ? defaultMinQuantity : this.minQuantity,
      filled: this.filled,
      equity: this.equity as Equity,
      rate: this.rate as Decimal,
      orderType: this.orderType,
      timeInForceType: this.timeInForceType,
      settlementType: this.settlementType,
      counterparties: this.counterparties,
      independentAmountRate: this.independentAmountRate,
      roundingRule: this.roundingRule,
      isAnonymous: this.isAnonymous,
    };
  }

  @Watch('minQuantity')
  protected onMinQuantityChanged(): void {
    if (!this.quantity || !this.minQuantity) return;

    // the moment the minQuantity is changed to something that isn't the quantity or the default
    // it must be that the user manually touched the field
    if (this.minQuantity !== this.quantity && this.minQuantity !== defaultMinQuantity) {
      this.minQuantityTouched = true;
    }
  }

  @Watch('quantity')
  protected onQuantityChanged(): void {
    if (!this.quantity) return;

    // as long as the min quantity has not been touched by the user we make it follow the quantity (up to max 100)
    if (!this.minQuantityTouched) {
      this.minQuantity = Math.min(this.quantity, defaultMinQuantity);
    }
  }

  protected created(): void {
    if (!this.order) {
      // We don't have an order yet (create mode)
      if (this.newOrderSide) {
        this.side = this.newOrderSide;
      }
      if (this.newOrderEquity) {
        this.equity = this.newOrderEquity;
      }
      this.independentAmountRate = this.loginState.user.companyPreferredIndependentAmountRate;
      this.roundingRule =
        this.loginState.user.companyPreferredRoundingRule === null
          ? RoundingRule.NoRounding
          : this.loginState.user.companyPreferredRoundingRule;
      this.counterparties = this.loginState.user.companyDefaultOmsCounterparties || [];
      return;
    }

    // Pull data from current order (edit) to be used inside the form
    this.side = this.order.side;
    this.quantity = this.order.quantity;
    this.minQuantity = this.order.minQuantity;
    this.filled = this.order.filled;
    this.equity = this.order.equity;
    this.rate = this.order.rate;
    this.orderType = this.order.orderType;
    this.timeInForceType = this.order.timeInForceType;
    this.settlementType = this.order.settlementType;
    this.counterparties = this.order.counterparties || [];
    this.independentAmountRate = this.order.independentAmountRate;
    this.roundingRule = this.order.roundingRule;
    this.isAnonymous = this.order.isAnonymous;

    if (this.minQuantity > defaultMinQuantity) {
      this.minQuantityTouched = true;
    } else {
      this.minQuantityTouched = this.quantity !== this.minQuantity;
    }
  }

  protected async submitForm(routingStatus: Api.Marketplace.Order['routingStatus']): Promise<void> {
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      if (this.order) {
        await this.$api.marketplace.editOrder({
          orderRef: this.order.orderRef,
          ...this.pickCommonFormFields(routingStatus),
        });

        this.$emit('action', {
          orderRef: this.order.orderRef,
          message: 'Order successfully edited',
          // only show button if OrderDetailsDialog is closed
        });
      } else {
        const { orderRef } = await this.$api.marketplace.createOrder({
          side: this.formFields.side,
          equityId: this.formFields.equity.id,
          ...this.pickCommonFormFields(routingStatus),
        });

        this.$emit('action', {
          orderRef: orderRef,
          message: 'Order successfully created',
        });
      }

      await this.closeDialog();
      this.$emit('success');
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [`Operation Failed: ${errorMessage}`];
      this.formStatus = 'idle';
    }
  }

  protected pickCommonFormFields(
    routingStatus: Api.Marketplace.Order['routingStatus']
  ): Omit<Api.Marketplace.OrderCommonFormRequest, 'openQuantity' | 'totalValue'> {
    return {
      routingStatus,
      rate: this.formFields.rate,
      quantity: this.formFields.quantity,
      minQuantity: this.formFields.minQuantity,
      counterparties: (this.formFields.counterparties || []).map((c) => c.companyId),
      independentAmountRate: this.formFields.independentAmountRate,
      orderType: this.formFields.orderType,
      roundingRule: this.formFields.roundingRule,
      settlementType: this.formFields.settlementType,
      timeInForceType: this.formFields.timeInForceType,
      isAnonymous: this.formFields.isAnonymous,
    };
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  protected goToSummary(): void {
    if (!this.validateForm()) {
      if (this.$v.minQuantity.$invalid) {
        // minQuantity is hidden because "advancaded fields" is not expanded by default
        // the other "advanced fields" won't become invalid because
        // they are either required or have a default value
        this.expandedPanel = 0;
      }
      return;
    }

    // get price from passed order prop if edit mode
    // or from selected equity if create mode
    const lastClosePrice = this.order
      ? this.order.equity.lastClosePrice
      : this.equity
        ? this.equity.lastClosePrice
        : null;

    const notional = calculateOrderNotional(this.quantity, lastClosePrice);

    // will stop workflow and display soft/hard limits dialog if needed
    new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
      notional,
      () => (this.showSummary = true)
    );
  }

  protected goBack(): void {
    this.showSummary = false;
    this.$v.$reset();
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected prettyNumber(value: number): string {
    return formatPrettyNumber(value);
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }
}
</script>

<style lang="scss" scoped>
.col-6 + .col-6 {
  padding-left: 12px;
}

::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
