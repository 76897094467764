<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card class="flex d-flex flex-column fill-height">
    <v-card-text>
      <!-- add user account -->
      <v-btn class="ma-6" color="primary" @click="createUser">
        <v-icon left> mdi-plus</v-icon>
        {{ $t('adminAddUser') }}
      </v-btn>

      <!-- search bar -->
      <v-text-field
        v-model="tableSearch"
        class="mx-6"
        clearable
        :label="$t('adminBrokerSearchUser')"
        prepend-inner-icon="mdi-magnify"
        @input="setQuickFilterText(tableSearch)"
      />
    </v-card-text>

    <ag-table-client
      v-if="users"
      :column-defs="columnDefs"
      :get-row-id="getRowId"
      :page-size="1000"
      :row-data="users"
      :sort="{ colId: 'name', sort: 'asc' }"
      @ready="onReady"
    />

    <!-- at least 1 company before adding users! -->
    <v-alert
      v-if="!companies.length"
      border="left"
      colored-border
      elevation="2"
      text
      type="warning"
    >
      <span v-html="$t('noTraderCompanies')"></span>
    </v-alert>

    <!-- edit user popup -->
    <broker-user-dialog
      v-if="dialogOptions.isActive"
      :crud-action="dialogOptions.crudAction"
      :title="dialogOptions.title"
      :user="dialogBuffer"
      @close-modal="closeModal"
    ></broker-user-dialog>
  </v-card>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import BrokerUserDialog from '@/modules/broker-admin/components/BrokerUserDialog.vue';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import { AppState } from '@/store/store';
import { formatDate } from '@/utils/helpers/dates';
import { RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { cloneDeep, noop } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/users';
import { ColDef, GetRowIdParams } from 'ag-grid-enterprise';

const defaultUser = {
  id: '0',
  companyID: '0',
  name: '',
  emailAddress: '',
  roles: [],
  tradingPermissions: null,
};

@Component({
  components: {
    BrokerUserDialog,
    AgTableClient,
  },
  props: {
    userFilter: String,
  },
  methods: {
    ...mapActions([
      'fetchBrokerAdminUsers',
      'fetchBrokerCompanies',
      'deleteBrokerAdminUser',
      'disableBrokerAdminUser',
      'enableBrokerAdminUser',
      'approveBrokerAdminUser',
      'approveBrokerAdminRoles',
    ]),
  },
  computed: {
    ...mapState(['users', 'companies']),
    ...mapGetters(['hasBrokerComplianceRepRole']),
  },
})
export default class BrokerUsers extends Vue {
  // Props
  protected readonly userFilter!: string;

  // Store state
  protected readonly users!: AppState['users'];
  protected readonly companies!: AppState['companies'];
  protected readonly hasBrokerComplianceRepRole!: boolean;
  // Store actions
  protected readonly fetchBrokerCompanies!: () => void;
  protected readonly fetchBrokerAdminUsers!: () => void;
  protected readonly deleteBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly disableBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly enableBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly approveBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly approveBrokerAdminRoles!: (user: UserAccount) => void;

  protected tableSearch = '';
  protected setQuickFilterText!: (text: string) => void;

  protected addBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'User',
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  public get columnDefs(): ColDef[] {
    return this.allColumnDefs();
  }

  @Watch('userFilter')
  protected onChangeFilter(newFilter: string): void {
    this.tableSearch = newFilter;
  }

  @Watch('tableSearch')
  protected onChangeSearch(newQuery: string): void {
    // update the query params
    void this.$router.replace({ query: { filter: newQuery } }).catch(noop); // ignore dup nav error
  }

  protected onReady(config: { setQuickFilterText: (text: string) => void }): void {
    this.setQuickFilterText = config.setQuickFilterText;
  }

  protected async mounted(): Promise<void> {
    this.tableSearch = this.userFilter;

    try {
      await this.fetchBrokerAdminUsers();
      await this.fetchBrokerCompanies();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected allColumnDefs(): ColDef[] {
    return [
      cols.name(),
      cols.longCompanyName(),
      cols.email(),
      cols.role(),
      cols.tradingPermissions(),
      cols.accountStatus(),
      cols.tfa(),
      cols.lastLogin(),
      cols.lastActive(), // @TODO: test it
      cols.totalActiveTime(), // @TODO: test it
      cols.actions({
        editUser: this.editUser,
        deleteUser: this.deleteUser,
        disableUser: this.disableUser,
        enableUser: this.enableUser,
      }),
    ];
  }

  protected getRowId(params: GetRowIdParams<UserAccount>): string {
    return params.data.id;
  }

  protected createUser(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add User';

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New User` and return with partially filled record
    // browsing the user list will not destroy the partially filled record
    if (this.addBuffer == null) {
      this.addBuffer = cloneDeep(defaultUser);
    }
    this.dialogBuffer = this.addBuffer;
  }

  protected editUser(rowUser: UserAccount): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Update User Profile';
    // do not modify the row while typing
    this.dialogBuffer = cloneDeep(rowUser);
  }

  protected closeModal(): void {
    // wipe user data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminDeleteUser'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('adminDeleteUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDeleteUser'),
      onAccept: () => this.doDeleteUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected disableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminDisableUser'),
      color: 'warning',
      icon: 'mdi-account-off',
      message: i18n.t('adminDisableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDisableUser'),
      onAccept: () => this.doDisableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected enableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminEnableUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminEnableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminEnableUser'),
      onAccept: () => this.doEnableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected approveUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminApproveUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminApproveUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
        role: i18n.t(rowUser.roles[0]),
        tradingPermissions:
          rowUser.tradingPermissions !== null
            ? i18n.t(`tradingPermissions.options.${rowUser.tradingPermissions}`)
            : i18n.t(`tradingPermissions.inherit`),
      }),
      acceptText: i18n.t('adminApproveUser'),
      onAccept: () => this.doApproveUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected formattedDate(date: Date): string {
    return formatDate(date, 'hh:mm:ss MM/dd/yy');
  }

  private approveUserRoles(rowUser: UserAccount) {
    this.$dialog.ask({
      title: i18n.t('adminApproveRoles'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminApproveRolesMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
        role: i18n.t(rowUser.roles[0]),
        newRole: i18n.t(`${rowUser.rolesPendingApproval && rowUser.rolesPendingApproval[0]}`),
        tradingPermissions:
          rowUser.tradingPermissions !== null
            ? i18n.t(`tradingPermissions.options.${rowUser.tradingPermissions}`)
            : i18n.t(`tradingPermissions.inherit`),
      }) as string,
      acceptText: i18n.t('adminApproveRoles') as string,
      onAccept: () => this.doApproveRoles(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteUser(rowUser: UserAccount) {
    try {
      await this.deleteBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDeletedUserMessage'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doDisableUser(rowUser: UserAccount) {
    try {
      await this.disableBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDisabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doEnableUser(rowUser: UserAccount) {
    try {
      await this.enableBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminEnabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doApproveUser(rowUser: UserAccount) {
    try {
      await this.approveBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminApprovedUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doApproveRoles(rowUser: UserAccount) {
    try {
      await this.approveBrokerAdminRoles(rowUser);

      this.$snackbar.confirm(i18n.tc('adminApprovedRoles'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }
}
</script>

<style lang="scss" scoped></style>
