// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/login/login.proto (package aurora.core.apiengine.loginv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithApiCredentialsRequest
 */
export class LoginWithApiCredentialsRequest extends Message<LoginWithApiCredentialsRequest> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string secret = 2;
   */
  secret = "";

  constructor(data?: PartialMessage<LoginWithApiCredentialsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithApiCredentialsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithApiCredentialsRequest {
    return new LoginWithApiCredentialsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithApiCredentialsRequest {
    return new LoginWithApiCredentialsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithApiCredentialsRequest {
    return new LoginWithApiCredentialsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithApiCredentialsRequest | PlainMessage<LoginWithApiCredentialsRequest> | undefined, b: LoginWithApiCredentialsRequest | PlainMessage<LoginWithApiCredentialsRequest> | undefined): boolean {
    return proto3.util.equals(LoginWithApiCredentialsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithApiCredentialsResponse
 */
export class LoginWithApiCredentialsResponse extends Message<LoginWithApiCredentialsResponse> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  constructor(data?: PartialMessage<LoginWithApiCredentialsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithApiCredentialsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithApiCredentialsResponse {
    return new LoginWithApiCredentialsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithApiCredentialsResponse {
    return new LoginWithApiCredentialsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithApiCredentialsResponse {
    return new LoginWithApiCredentialsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithApiCredentialsResponse | PlainMessage<LoginWithApiCredentialsResponse> | undefined, b: LoginWithApiCredentialsResponse | PlainMessage<LoginWithApiCredentialsResponse> | undefined): boolean {
    return proto3.util.equals(LoginWithApiCredentialsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithUserCredentialsRequest
 */
export class LoginWithUserCredentialsRequest extends Message<LoginWithUserCredentialsRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: optional string otp_code = 3;
   */
  otpCode?: string;

  constructor(data?: PartialMessage<LoginWithUserCredentialsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithUserCredentialsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "otp_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithUserCredentialsRequest {
    return new LoginWithUserCredentialsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithUserCredentialsRequest {
    return new LoginWithUserCredentialsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithUserCredentialsRequest {
    return new LoginWithUserCredentialsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithUserCredentialsRequest | PlainMessage<LoginWithUserCredentialsRequest> | undefined, b: LoginWithUserCredentialsRequest | PlainMessage<LoginWithUserCredentialsRequest> | undefined): boolean {
    return proto3.util.equals(LoginWithUserCredentialsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithUserCredentialsResponse
 */
export class LoginWithUserCredentialsResponse extends Message<LoginWithUserCredentialsResponse> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  /**
   * @generated from field: string refresh_token = 2;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<LoginWithUserCredentialsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithUserCredentialsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithUserCredentialsResponse {
    return new LoginWithUserCredentialsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithUserCredentialsResponse {
    return new LoginWithUserCredentialsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithUserCredentialsResponse {
    return new LoginWithUserCredentialsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithUserCredentialsResponse | PlainMessage<LoginWithUserCredentialsResponse> | undefined, b: LoginWithUserCredentialsResponse | PlainMessage<LoginWithUserCredentialsResponse> | undefined): boolean {
    return proto3.util.equals(LoginWithUserCredentialsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.RefreshTokenRequest
 */
export class RefreshTokenRequest extends Message<RefreshTokenRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<RefreshTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.RefreshTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined, b: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined): boolean {
    return proto3.util.equals(RefreshTokenRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.RefreshTokenResponse
 */
export class RefreshTokenResponse extends Message<RefreshTokenResponse> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  /**
   * @generated from field: string refresh_token = 2;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<RefreshTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.RefreshTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined, b: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined): boolean {
    return proto3.util.equals(RefreshTokenResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.ConfirmIdentityRequest
 */
export class ConfirmIdentityRequest extends Message<ConfirmIdentityRequest> {
  /**
   * @generated from field: string secret = 1;
   */
  secret = "";

  constructor(data?: PartialMessage<ConfirmIdentityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.ConfirmIdentityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmIdentityRequest {
    return new ConfirmIdentityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmIdentityRequest {
    return new ConfirmIdentityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmIdentityRequest {
    return new ConfirmIdentityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmIdentityRequest | PlainMessage<ConfirmIdentityRequest> | undefined, b: ConfirmIdentityRequest | PlainMessage<ConfirmIdentityRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmIdentityRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.ConfirmIdentityResponse
 */
export class ConfirmIdentityResponse extends Message<ConfirmIdentityResponse> {
  constructor(data?: PartialMessage<ConfirmIdentityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.ConfirmIdentityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmIdentityResponse {
    return new ConfirmIdentityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmIdentityResponse {
    return new ConfirmIdentityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmIdentityResponse {
    return new ConfirmIdentityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmIdentityResponse | PlainMessage<ConfirmIdentityResponse> | undefined, b: ConfirmIdentityResponse | PlainMessage<ConfirmIdentityResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmIdentityResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithSAMLTicketRequest
 */
export class LoginWithSAMLTicketRequest extends Message<LoginWithSAMLTicketRequest> {
  /**
   * @generated from field: string ticket = 1;
   */
  ticket = "";

  constructor(data?: PartialMessage<LoginWithSAMLTicketRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithSAMLTicketRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ticket", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithSAMLTicketRequest {
    return new LoginWithSAMLTicketRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithSAMLTicketRequest {
    return new LoginWithSAMLTicketRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithSAMLTicketRequest {
    return new LoginWithSAMLTicketRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithSAMLTicketRequest | PlainMessage<LoginWithSAMLTicketRequest> | undefined, b: LoginWithSAMLTicketRequest | PlainMessage<LoginWithSAMLTicketRequest> | undefined): boolean {
    return proto3.util.equals(LoginWithSAMLTicketRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LoginWithSAMLTicketResponse
 */
export class LoginWithSAMLTicketResponse extends Message<LoginWithSAMLTicketResponse> {
  /**
   * @generated from field: string accessToken = 1;
   */
  accessToken = "";

  /**
   * @generated from field: string refreshToken = 2;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<LoginWithSAMLTicketResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LoginWithSAMLTicketResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refreshToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithSAMLTicketResponse {
    return new LoginWithSAMLTicketResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithSAMLTicketResponse {
    return new LoginWithSAMLTicketResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithSAMLTicketResponse {
    return new LoginWithSAMLTicketResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithSAMLTicketResponse | PlainMessage<LoginWithSAMLTicketResponse> | undefined, b: LoginWithSAMLTicketResponse | PlainMessage<LoginWithSAMLTicketResponse> | undefined): boolean {
    return proto3.util.equals(LoginWithSAMLTicketResponse, a, b);
  }
}

