<!-- this is a demo feature not intended to go to PROD -->
<template>
  <div>
    <dashboard-panel no-collapse :title="panelTitle">
      <reported-transaction-list />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import { mapState } from 'vuex';
import i18n from '@/localisation/i18n';
import ReportedTransactionList from '@/modules/finra/components/ReportedTransactionsList.vue';

@Component({
  components: {
    ReportedTransactionList,
    DashboardPanel,
  },
  computed: {
    ...mapState(['loginState']),
  },
})
export default class ReportedTransactions extends Vue {
  protected get panelTitle(): string {
    return i18n.t('sidebar.items.reportedTransactions') as string;
  }
}
</script>

<style lang="scss"></style>
