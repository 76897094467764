<template>
  <v-select
    :clearable="clearable"
    :error-messages="errorMessages"
    :items="items"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { loanEvents, newLoanEvents } from '@/utils/api/loans';

@Component({
  props: {
    value: Number,
    label: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: 'select loan event...',
    },
    clearable: {
      type: Boolean,
    },
  },
})
export default class LoanEventSelect extends Vue {
  protected readonly value!: number;
  protected readonly label!: string;
  protected readonly errorMessages!: string[];
  protected readonly placeholder!: string;
  protected readonly clearable?: boolean;

  protected get items(): Array<{ value: number; text: string; color: string }> {
    return Object.entries(newLoanEvents).map(([key, value]) => ({
      value: parseInt(key, 10),
      text: loanEvents[value].label,
      color: loanEvents[value].color,
    }));
  }
}
</script>
