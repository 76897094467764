<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
         with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <open-loans-action-manager ref="actionManager" />
    <div class="actions d-flex justify-end">
      <v-btn
        class="icon-action"
        data-test="view-loan"
        icon
        x-small
        @click="$emit('view-loan', item)"
      >
        <v-icon size="24">mdi-eye</v-icon>
      </v-btn>
      <btn-dropdown
        v-if="showDropdownActions"
        color="secondary"
        data-test="actions-dropdown"
        :disabled="!hasOpsUserRole || Object.keys(item.availableActions).length === 0"
        main-text="Actions"
        x-small
      >
        <v-list id="dropdown-actions" ref="dropdown-actions" dense>
          <aurora-list-item
            v-if="item.availableActions.cancelPending"
            data-test="cancel-pending-menu-item"
            timeframe="cancelLoans"
            @click="$refs.actionManager.trigger({ name: 'cancelPendingLoan', loan: item })"
          >
            <v-list-item-title>Cancel</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.return"
            data-test="return-menu-item"
            timeframe="settleLoans"
            @click="$refs.actionManager.trigger({ name: 'return', loan: item })"
          >
            <v-list-item-title>Return</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.cancelReturn"
            data-test="cancel-return-menu-item"
            timeframe="settleLoans"
            @click="$refs.actionManager.trigger({ name: 'cancelPendingReturns', loan: item })"
          >
            <v-list-item-title>Cancel return</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.recall"
            data-test="recall-menu-item"
            timeframe="recallLoans"
            @click="$refs.actionManager.trigger({ name: 'recall', loan: item })"
          >
            <v-list-item-title>Add recall</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.updateRecall"
            data-test="update-recall-menu-item"
            timeframe="recallLoans"
            @click="$emit('view-loan', item, 'recalls')"
          >
            <v-list-item-title>Manage recalls</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.buyIn"
            data-test="buy-in-menu-item"
            timeframe="buyInLoans"
            @click="$refs.actionManager.trigger({ name: 'buyIn', loan: item })"
          >
            <v-list-item-title>Buy-in</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="
              item.availableActions.renegotiateFixed || item.availableActions.renegotiateFloating
            "
            data-test="renegotiate-menu-item"
            :disabled="!hasTraderUserRole"
            timeframe="rateModLoans"
            @click="$refs.actionManager.trigger({ name: 'renegotiate', loan: item })"
          >
            <v-list-item-title>Rerate</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.cancelRenegotiate"
            data-test="cancel-renegotiate-menu-item"
            :disabled="!hasTraderUserRole"
            @click="$refs.actionManager.trigger({ name: 'cancelRenegotiate', loan: item })"
          >
            <v-list-item-title>Cancel rerate</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.acceptRenegotiate"
            data-test="accept-renegotiate-menu-item"
            :disabled="!hasTraderUserRole"
            timeframe="rateModLoans"
            @click="$refs.actionManager.trigger({ name: 'acceptRenegotiate', loan: item })"
          >
            <v-list-item-title>Accept rerate</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="item.availableActions.rejectRenegotiate"
            data-test="reject-renegotiate-menu-item"
            :disabled="!hasTraderUserRole"
            timeframe="rateModLoans"
            @click="$refs.actionManager.trigger({ name: 'rejectRenegotiate', loan: item })"
          >
            <v-list-item-title>Reject rerate</v-list-item-title>
          </aurora-list-item>

          <aurora-list-item
            v-if="
              item.availableActions.acceptRenegotiate || item.availableActions.rejectRenegotiate
            "
            data-test="reject-and-counter-renegotiate-menu-item"
            :disabled="!hasTraderUserRole"
            timeframe="rateModLoans"
            @click="
              $refs.actionManager.trigger({
                name: 'rejectRenegotiateAndCounterOffer',
                loan: item,
              })
            "
          >
            <v-list-item-title>Reject & Counter Rerate</v-list-item-title>
          </aurora-list-item>
        </v-list>
      </btn-dropdown>
    </div>
  </span>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import OpenLoansActionManager from '@/modules/open-loans/components/OpenLoansActionManager.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { PropType } from 'vue';

@Component({
  components: {
    BtnDropdown,
    OpenLoansActionManager,
  },
  props: {
    item: { type: Object as PropType<OpenLoanItem>, required: true },
    showDropdownActions: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class OpenLoansTableActions extends Vue {
  public $refs!: {
    actionManager: OpenLoansActionManager;
  };

  // props
  protected readonly item!: OpenLoanItem;
  protected readonly showDropdownActions!: boolean;

  // store state
  protected readonly hasOpsUserRole!: boolean;
  protected readonly hasTraderUserRole!: boolean;
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}
</style>
