/* eslint-disable vue/one-component-per-file */

import { Decimal } from 'decimal.js';
import { ColDef } from 'ag-grid-enterprise';
import { VChip } from 'vuetify/lib';
import { Api } from '@/modules/common/types/api';
import { alignCenter, alignLeft, alignRight, component } from './utils';
import { getStatus, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import {
  formatCompanyBoxId,
  formatCompanyName,
} from '@/modules/user-accounts/helpers/user-accounts';
import MarketplaceOrdersTableActions from '@/modules/marketplace/components/MarketplaceOrdersTableActions.vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { h } from 'vue';
import PrettyNumber from '@/modules/common/components/pretty-number/PrettyNumber.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
export { checkbox, cusip, side, ticker, rate, settlementType } from './common';

const statusAdapter = component<{
  order: Api.Marketplace.Order;
  viewOrder: (orderRef: string) => void;
  label: string;
  color: string;
}>(
  (props) => () =>
    h(
      VChip,
      {
        style: 'width: 4rem',
        class: 'justify-center text-lowercase',
        props: { color: props.color, small: true },
        on: { click: () => props.viewOrder(props.order.orderRef) },
      },
      props.label
    )
);
export function status({ viewOrder }: { viewOrder: (orderRef: string) => void }): ColDef {
  return {
    field: 'status',
    colId: 'status',
    headerName: 'Status',
    cellRendererSelector: (params) =>
      statusAdapter({
        order: params.data,
        viewOrder,
        ...getStatus(params.data),
      }),
    resizable: false,
    pinned: 'left',
    width: 120,
    ...alignCenter(),
  };
}

export function active(): ColDef {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Active',
    valueFormatter: (params) =>
      params.value === 'ROUTED' ? 'Active' : params.value === 'PENDING' ? 'Pending' : '–',
    ...alignLeft(),
  };
}

export function company(): ColDef {
  return {
    field: 'company',
    colId: 'company',
    headerName: 'Company',
    valueFormatter: (params) => formatCompanyName(params.value),
    ...alignLeft(),
  };
}

export const openQuantity = (): ColDef =>
  cols.quantity({ field: 'openQuantity', headerName: 'Open' });

export const execQty = (): ColDef => cols.quantity({ field: 'filled', headerName: 'Exec Qty' });

const avgExecutionRateAdapter = component<{
  rate: number | string | Decimal | null;
}>((props) => () => (props.rate === null ? h('span', '-') : h(RateOutput, { props })));

export function avgExecRate(): ColDef {
  return {
    field: 'avgExecutionRate',
    colId: 'avgExecutionRate',
    headerName: 'Avg Exec Rate',
    cellRendererSelector: (params) =>
      avgExecutionRateAdapter({
        rate: params.value,
      }),
    cellDataType: 'text',
    ...alignRight(),
  };
}

export const totalQuantity = (): ColDef =>
  cols.quantity({ field: 'quantity', headerName: 'Total Qty' });

export const createTime = (): ColDef =>
  cols.timestamp({ field: 'createdAt', headerName: 'Create Time' });

const orderTypeAdapter = component<{ orderType: string }>(
  (props) => () => h('span', { class: 'text-capitalize' }, props.orderType.toLowerCase())
);

export function orderType(): ColDef {
  return {
    field: 'orderType',
    colId: 'orderType',
    headerName: 'Type',
    cellRendererSelector: (params) =>
      orderTypeAdapter({
        orderType: params.value,
      }),
    ...alignLeft(),
  };
}

export function timeInForce(): ColDef {
  return {
    field: 'timeInForceType',
    colId: 'timeInForceType',
    headerName: 'Time in Force',
    valueFormatter: (params) => timeInForceAbbr(params.value),
    ...alignLeft(),
  };
}

const minQuantityAdapter = component<{ minQuantity: number }>(
  (props) => () =>
    props.minQuantity === 1
      ? h('span', '–')
      : h(PrettyNumber, { props: { value: props.minQuantity } })
);

export function minQuantity(): ColDef {
  return {
    field: 'minQuantity',
    colId: 'minQuantity',
    headerName: 'Min. Quantity',
    cellRendererSelector: (params) =>
      minQuantityAdapter({
        minQuantity: params.value,
      }),
    ...alignRight(),
  };
}

export function counterparties(): ColDef {
  return {
    field: 'counterparties',
    colId: 'counterparties',
    headerName: 'Counterparties',
    valueFormatter: (params) =>
      params.value.length ? params.value.map(formatCompanyBoxId).join(', ') : 'Any',
    ...alignLeft(),
  };
}

export function orderRef(): ColDef {
  return {
    field: 'orderRef',
    colId: 'orderRef',
    headerName: 'OrderRef',
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  order: Api.Marketplace.Order;
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Api.Marketplace.Order) => void;
  actions?: string[];
}>((props) => () => h(MarketplaceOrdersTableActions, { props }));

export function actions({
  viewOrder,
  editOrder,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actions = ['view', 'edit', 'route', 'unroute', 'cancel'],
}: {
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Api.Marketplace.Order) => void;
  actions?: string[];
}): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        order: params.data,
        viewOrder,
        editOrder,
        actions,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
