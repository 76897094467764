import { ApiService } from '@/modules/common/services/api.service';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import { normalizeBrokerOpenLoan } from '@/utils/api/broker';
import { LenderOpenLoan } from '@/utils/api/lender';
import {
  AggregatedLoans,
  AggregatedLoansRequest,
  AggregatedOpenLoansParams,
  BrokerOpenLoans,
  BrokerOpenLoansParams,
  BrokerOpenLoansRequest,
  LoanDetails,
  LoanDetailsLoan,
  LoanEvent,
  OpenLoans,
  OpenLoansParams,
  OpenLoansRequest,
  RenegotiateRequest,
  loanDetailsFromApiResponse,
  normalizeAggregatedLoan,
  normalizeOpenLoan,
} from '@/utils/api/loans';
import { ApiError } from '@/utils/errors';
import axios from 'axios';
import Decimal from 'decimal.js';
import Vue from 'vue';

export class OpenLoansApiService extends ApiService {
  private featureUrl = '/open-loans';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.openLoans = singleton;
    } else {
      v.prototype.$api = {
        lenderLoans: singleton,
      };
    }
  }

  public async fetchLoanDetails(
    loanId: string,
    abortSignal: AbortSignal
  ): Promise<LoanDetails | void> {
    const url = this.baseUrl + `/loan-history/${loanId}`;

    try {
      const { data } = await this.axios.get<{
        items: LoanEvent[];
        summary: LoanDetailsLoan;
      }>(url, { signal: abortSignal });

      return loanDetailsFromApiResponse(data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async fetchBrokerLoanDetails(
    loanId: string,
    abortSignal: AbortSignal
  ): Promise<LoanDetails | void> {
    const url = this.baseUrl + `/broker-user/monitor/loan-history/${loanId}`;

    try {
      const { data } = await this.axios.get<{
        items: LoanEvent[];
        summary: LoanDetailsLoan;
      }>(url, { signal: abortSignal });

      return loanDetailsFromApiResponse(data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  // About the fetch methods below:
  //
  // 1. Overload to support request cancellation
  // 2. Build request params using provided params + default values
  // 3. Make request
  // 4. Normalize response data
  // 5. Return normalized data (or void if request was cancelled)

  public async fetchOpenLoans(openLoansState: OpenLoansParams): Promise<OpenLoans>;
  public async fetchOpenLoans(
    openLoansState: OpenLoansParams,
    abortSignal: AbortSignal
  ): Promise<OpenLoans | void>;
  public async fetchOpenLoans(
    openLoansState: OpenLoansParams,
    abortSignal?: AbortSignal
  ): Promise<OpenLoans | void> {
    const defaultOpenLoansState: OpenLoansRequest = {
      filters: {
        counterpartyCompanyId: null,
        cusip: null,
        side: null,
        showAll: false,
        isRecalled: false,
        isRenegotiating: false,
      },
      sort: 'createdAt',
      pagination: {
        page: 1,
        limit: 50,
      },
    };
    openLoansState = { ...defaultOpenLoansState, ...openLoansState };
    const params = {
      sort: openLoansState.sort,
      ...openLoansState.filters,
      ...openLoansState.pagination,
    };

    const url = this.baseUrl + this.featureUrl;

    try {
      const { data } = await this.axios.get<OpenLoans>(url, { params, signal: abortSignal });

      data.items.forEach((loan) => normalizeOpenLoan(loan, data.corporateActions));

      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async fetchBrokerOpenLoans(
    openLoansState: BrokerOpenLoansParams
  ): Promise<BrokerOpenLoans>;
  public async fetchBrokerOpenLoans(
    openLoansState: BrokerOpenLoansParams,
    abortSignal: AbortSignal
  ): Promise<BrokerOpenLoans | void>;
  public async fetchBrokerOpenLoans(
    brokerOpenLoansState: BrokerOpenLoansParams,
    abortSignal?: AbortSignal
  ): Promise<BrokerOpenLoans | void> {
    const defaultBrokerOpenLoansState: BrokerOpenLoansRequest = {
      filters: { lender: null, borrower: null, cusip: null, statuses: null, displayId: null },
      sort: '-createdAt',
      pagination: { page: 1, limit: 50 },
    };
    brokerOpenLoansState = { ...defaultBrokerOpenLoansState, ...brokerOpenLoansState };
    const params = {
      sort: brokerOpenLoansState.sort,
      ...brokerOpenLoansState.filters,
      ...brokerOpenLoansState.pagination,
    };
    const url = this.baseUrl + '/broker-user/monitor/open-loans';

    try {
      const { data } = await this.axios.get<BrokerOpenLoans>(url, { params, signal: abortSignal });
      data.items.forEach((loan) => normalizeBrokerOpenLoan(loan, data.corporateActions));
      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async fetchAggregatedOpenLoans(
    aggregatedLoansState: AggregatedOpenLoansParams
  ): Promise<AggregatedLoans>;
  public async fetchAggregatedOpenLoans(
    aggregatedLoansState: AggregatedOpenLoansParams,
    abortSignal: AbortSignal
  ): Promise<AggregatedLoans | void>;
  public async fetchAggregatedOpenLoans(
    aggregatedLoansState: AggregatedOpenLoansParams,
    abortSignal?: AbortSignal
  ): Promise<AggregatedLoans | void> {
    const defaultAggregatedLoansState: AggregatedLoansRequest = {
      group: 'security',
      filters: {
        side: null,
        counterpartyCompanyId: null,
        cusip: null,
      },
      sort: null,
      pagination: { page: 1, limit: 50 },
    };
    aggregatedLoansState = { ...defaultAggregatedLoansState, ...aggregatedLoansState };
    const params = {
      sort: aggregatedLoansState.sort,
      ...aggregatedLoansState.filters,
      ...aggregatedLoansState.pagination,
    };
    const url = `${this.baseUrl}${this.featureUrl}/${
      aggregatedLoansState.group == 'security' ? 'by-security' : 'by-counterparty'
    }`;

    try {
      const { data } = await this.axios.get<AggregatedLoans>(url, { params, signal: abortSignal });
      data.items.forEach((agg) => {
        normalizeAggregatedLoan(agg);
      });
      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async cancelRecall(loanDisplayId: string, recallId: string): Promise<void> {
    const url = this.baseUrl + `/lender/loans/${loanDisplayId}/recall/${recallId}`;
    await this.axios.delete(url);
  }

  public async postRenegotiate(payload: RenegotiateRequest): Promise<void> {
    const url = this.baseUrl + `/renegotiate/${payload.side}/loan/${payload.loanId}`;
    return this.axios.post(url, payload).then(() => undefined);
  }

  public async respondToRenegotiate(
    loan: BorrowerOpenLoan | LenderOpenLoan,
    accepted: boolean
  ): Promise<void> {
    const url = this.baseUrl + `/renegotiate/${loan.side}/loan/${loan.id}/respond`;
    return this.axios.post(url, { accepted }).then(() => undefined);
  }

  public async rejectRenegotiateAndCounter(
    loan: BorrowerOpenLoan | LenderOpenLoan,
    counterOffer: RenegotiateRequest
  ): Promise<void> {
    await this.respondToRenegotiate(loan, false);
    await this.postRenegotiate(counterOffer);
  }

  public async cancelRenegotiate(loan: BorrowerOpenLoan | LenderOpenLoan): Promise<void> {
    const url = this.baseUrl + `/renegotiate/${loan.side}/loan/${loan.id}/cancel`;
    return this.axios.delete(url).then(() => undefined);
  }

  public async cancelPendingLoan(loan: BorrowerOpenLoan | LenderOpenLoan): Promise<void> {
    const url = this.baseUrl + `/pending-loan/${loan.id}/cancel`;
    return this.axios.delete(url).then(() => undefined);
  }

  public async cancelPendingReturns(loan: BorrowerOpenLoan | LenderOpenLoan): Promise<void> {
    const url = this.baseUrl + `/borrower/loans/${loan.id}/cancel-return`;
    return this.axios.delete(url).then(() => undefined);
  }

  public async postRecall(
    loan: BorrowerOpenLoan | LenderOpenLoan,
    quantity: number
  ): Promise<void> {
    const url = this.baseUrl + `/lender/loans/${loan.displayId}/recall`;
    return this.axios.post(url, { quantity }).then(() => undefined);
  }

  public async postReturn(
    loan: BorrowerOpenLoan | LenderOpenLoan,
    quantity: number
  ): Promise<void> {
    const url = this.baseUrl + `/borrower/loans/${loan.displayId}/return`;
    return this.axios.post(url, { quantity }).then(() => undefined);
  }

  public async postBuyIn(
    loan: BorrowerOpenLoan | LenderOpenLoan,
    quantity: number,
    avgPricePerShare: Decimal
  ): Promise<void> {
    const url = this.baseUrl + `/lender/loans/${loan.displayId}/buyin`;
    return this.axios.post(url, { quantity, avgPricePerShare }).then(() => undefined);
  }
}
