<template>
  <v-data-table
    class="d-flex flex flex-column"
    dense
    disable-pagination
    disable-sort
    fixed-header
    :headers="tableColumns"
    height="100%"
    hide-default-footer
    :items="depthOfBook ? depthOfBook.data : []"
  >
    <template #[`item.borrowCounterparty`]="{ item }">
      <span v-if="item.borrow">{{ item.borrow.counterparty.companyName }}</span>
      <span v-else>–</span>
    </template>
    <template #[`item.borrowQuantity`]="{ item }">
      <pretty-number v-if="item.borrow" :value="item.borrow.quantity" />
      <span v-else>–</span>
    </template>
    <template #[`item.borrowIndependentAmountRate`]="{ item }">
      <rate-output v-if="item.borrow" :precision="2" :rate="item.borrow.independentAmountRate" />
      <span v-else>–</span>
    </template>
    <template #[`item.borrowRate`]="{ item }">
      <rate-output v-if="item.borrow" class="borrower-color" :rate="item.borrow.rate" />
      <span v-else>–</span>
    </template>
    <template #[`item.lendRate`]="{ item }">
      <rate-output v-if="item.lend" class="lender-color" :rate="item.lend.rate" />
      <span v-else>–</span>
    </template>
    <template #[`item.lendIndependentAmountRate`]="{ item }">
      <rate-output v-if="item.lend" :precision="2" :rate="item.lend.independentAmountRate" />
      <span v-else>–</span>
    </template>
    <template #[`item.lendQuantity`]="{ item }">
      <pretty-number v-if="item.lend" :value="item.lend.quantity" />
      <span v-else>–</span>
    </template>
    <template #[`item.lendCounterparty`]="{ item }">
      <span v-if="item.lend">{{ item.lend.counterparty.companyName }}</span>
      <span v-else>–</span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { Api } from '@/modules/common/types/api';
import { formatDate } from '@/utils/helpers/dates';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

const POLLING_INTERVAL = 1;

const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Counterparty',
    value: 'borrowCounterparty',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Quantity',
    value: 'borrowQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'IA Limit',
    value: 'borrowIndependentAmountRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Bid',
    value: 'borrowRate',
    class: 'text-truncate',
    cellClass: 'text-truncate highlighted-border-right',
  },
  {
    text: 'Ask',
    value: 'lendRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'IA',
    value: 'lendIndependentAmountRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Quantity',
    value: 'lendQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Counterparty',
    value: 'lendCounterparty',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
];

@Component({
  components: {
    RateOutput,
  },
  props: {
    cusip: String,
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
})
export default class MarketplaceDepthOfBook extends Vue {
  // props
  protected readonly cusip!: string;
  protected readonly asBroker!: boolean;

  protected depthOfBook: Api.Marketplace.DepthOfBookResponse | null = null;
  protected tableColumns = allTableHeaders;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  @Watch('cusip')
  protected async fetch(): Promise<void> {
    if (!this.cusip) {
      // the route that triggers the parent page could still be
      // redirecting between the route without cusip and the one with cusip
      return;
    }

    this.depthOfBook = this.asBroker
      ? await this.$api.marketplace.fetchAdminDepthOfBook(this.cusip)
      : await this.$api.marketplace.fetchDepthOfBook(this.cusip);
  }

  protected async mounted(): Promise<void> {
    await this.fetch();
    // @TODO: replace with socket events
    this.pollInterval = setInterval(() => this.fetch(), POLLING_INTERVAL * 1000);
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'HH:mm');
  }

  // vue-class-component implements destroyed, but not unmounted
  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .highlighted-border-right {
    border-right: 1px solid #ccc !important; /* @TODO: fix */
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }
}
</style>
