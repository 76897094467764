import Decimal from 'decimal.js';
import { parseISO } from 'date-fns';
import { Equity, EventInitiator } from '@/modules/common/types/api';
import { normalizeEquity } from '@/modules/common/helpers/api';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { LenderOpenLoan } from '@/utils/api/lender';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import { ensureDate, ensureDecimal } from '@/utils/api/helpers';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { BrokerOpenLoan } from '@/utils/api/broker';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { SettlementType } from '@/modules/marketplace/types/marketplace';
import { LoanHistoryEventType } from '@/connect/gen/modules/apiengine/services/loans/loans_pb';

export type Side = 'LENDER' | 'BORROWER' | 'all';

export type LoanStatus =
  | 'NEW'
  | 'PENDING'
  | 'REJECTED'
  | 'DROPPED'
  | 'CANCELED'
  | 'OPEN'
  | 'CLOSED'
  | 'TERMINATED'
  | 'UNDER_CORP_ACTION'
  | 'CANCEL_ROLL_PENDING'
  | 'CANCEL_RETURN_PENDING'
  | 'CANCEL_NEW_LOAN_PENDING'
  | 'ERROR';

type StatusColorString = 'error' | 'info' | 'green' | 'purple' | 'warning' | 'grey';

export const loanStatuses: Record<LoanStatus, { color: StatusColorString; label: string }> = {
  NEW: { color: 'info', label: 'New' },
  PENDING: { color: 'info', label: 'Pending' },
  REJECTED: { color: 'error', label: 'Rejected' },
  DROPPED: { color: 'error', label: 'Dropped' },
  CANCELED: { color: 'grey', label: 'Canceled' },
  OPEN: { color: 'green', label: 'Open' },
  CLOSED: { color: 'grey', label: 'Closed' },
  TERMINATED: { color: 'error', label: 'Terminated' },
  UNDER_CORP_ACTION: { color: 'warning', label: 'Undergoing Corp Action' },
  CANCEL_ROLL_PENDING: { color: 'warning', label: 'Cancel Roll Pending' },
  CANCEL_RETURN_PENDING: { color: 'warning', label: 'Cancel Return Pending' },
  CANCEL_NEW_LOAN_PENDING: { color: 'warning', label: 'Cancel Pending' },
  ERROR: { color: 'error', label: 'Error' },
};

export function getOpenLoanStatuses(): readonly LoanStatus[] {
  return Object.keys(loanStatuses)
    .map((key) => key as LoanStatus)
    .filter((s) => !isTerminalLoanStatus(s));
}

export function isTerminalLoanStatus(s: LoanStatus): boolean {
  switch (s) {
    case 'ERROR':
    case 'REJECTED':
    case 'DROPPED':
    case 'CANCELED':
    case 'CLOSED':
    case 'TERMINATED':
      return true;
    default:
      return false;
  }
}

export enum Benchmark {
  NoBenchmark = '',
  OBFR = 'OBFR',
  EFFR = 'EFFR',
  SOFR = 'SOFR',
  BGCR = 'BGCR',
  TGCR = 'TGCR',
  IORB = 'IORB',
}

export function getAllBenchmarks(): string[] {
  return Object.keys(Benchmark).reduce<string[]>((acc, next) => {
    acc.push(next);
    return acc;
  }, []);
}

export enum RenegotiateSide {
  Borrower = 'BORROWER',
  Lender = 'LENDER',
}

export interface RenegotiateRequest {
  side: RenegotiateSide;
  loanId: string;
  rate: Decimal;
  rateModifier: Benchmark;
}

export interface OpenLoan {
  id: string;
  displayId: string;
  equity: Equity;
  /**
   * remaining quantity open in the loan
   */
  openQuantity: number;
  pendingReturnQuantity: number;
  /**
   * returned quantity for today
   */
  returnedQuantityToday: number;
  /**
   * returned quantity in the whole lifetime of the loan
   */
  returnedQuantity: number;
  /**
   * current recalled quantity
   */
  recalledQuantity: number;
  contractAmount: Decimal;
  settlementAmount: Decimal;
  pendingBuyInQuantity: number;
  counterparty: CompanyInfo;
  counterpartyDisplay: string;
  rate: Decimal;
  rateModifier: Benchmark;
  independentAmountRate: Decimal;
  roundingRule: RoundingRule;
  createdAt: Date;
  updatedAt: Date;
  nextAllowedBuyInExecutionDate: Date | null;
  status: LoanStatus;
  renegotiation: LoanRenegotiation | null;
  openRecalls: OpenRecall[];
  settlementType: SettlementType;
  /**
   * the quantity that is available for return,
   * taking into account any pending returns
   */
  openQuantityToReturn: number;
  /**
   * quantity that is currently available for buy-in,
   * not accounting for any pending buy-in
   */
  allowedBuyInExecutionQuantity: number;
  /**
   * remaining quantity that can be recalled
   */
  openQuantityToRecall: number;
  /**
   * total quantity that is actually possible for buy-in,
   * taking into account any pending buy-in still to be processed
   */
  openQuantityToBuyIn: number;

  sponsorshipSide: 'sponsor' | 'sponsored' | null;
  termContractDisplayId: string | null;
  /**
   * all actions that can be performed, based
   * on the current state of the loan
   */
  availableActions: {
    [key in
      | 'cancelPending'
      | 'return'
      | 'cancelReturn'
      | 'recall'
      | 'updateRecall'
      | 'buyIn' // not available in batch actions yet
      | 'renegotiateFixed'
      | 'renegotiateFloating'
      | 'cancelRenegotiate'
      | 'acceptRenegotiate'
      | 'rejectRenegotiate']?: true;
  };
}

export interface OpenLoansRequest {
  filters: {
    showAll: boolean;
    isRecalled: boolean;
    isRenegotiating: boolean;
    side: 'LENDER' | 'BORROWER' | null;
    counterpartyCompanyId: string | null;
    cusip: string | null;
    isSponsored?: boolean; // when undefined returns both regular and sponsored loans
    termContractDisplayId?: string;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface OpenLoansParams {
  filters?: Partial<OpenLoansRequest['filters']>;
  sort?: Partial<OpenLoansRequest['sort']>;
  pagination?: Partial<OpenLoansRequest['pagination']>;
}

export interface OpenLoans {
  items: OpenLoanItem[];
  total: number;
  recalledTotal: number;
  rerateTotal: number;
  corporateActions: Record<string, true>;
}

export interface BrokerOpenLoansRequest {
  filters: {
    cusip: string | null;
    lender: string | null;
    borrower: string | null;
    statuses: string | null;
    displayId: string | null;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface BrokerOpenLoansParams {
  filters?: Partial<BrokerOpenLoansRequest['filters']>;
  sort?: Partial<BrokerOpenLoansRequest['sort']>;
  pagination?: Partial<BrokerOpenLoansRequest['pagination']>;
}

export interface BrokerOpenLoans {
  items: BrokerOpenLoan[];
  total: number;
  corporateActions: Record<string, true>;
}

export function normalizeOpenLoan(
  l: LenderOpenLoan | BorrowerOpenLoan | BrokerOpenLoan,
  corporateActions: Record<string, true>
): void {
  normalizeEquity(l.equity, corporateActions);
  normalizeOpenRecalls(l.openRecalls);

  // input from server will have rate as a string, we convert it to Decimal
  const lWithStrRate: { rate: string | Decimal } = l;
  if (typeof lWithStrRate.rate === 'string') {
    l.rate = new Decimal(lWithStrRate.rate);
  }

  // input from server will have independentAmountRate as a string, we convert it to Decimal
  const lWithStrIARate: { independentAmountRate: string | Decimal } = l;
  if (typeof lWithStrIARate.independentAmountRate === 'string') {
    l.independentAmountRate = new Decimal(lWithStrIARate.independentAmountRate);
  }

  // input from server will have contractAmount as a string, we convert it to Decimal
  const lWithStrContractAmount: { contractAmount: string | Decimal } = l;
  if (typeof lWithStrContractAmount.contractAmount === 'string') {
    l.contractAmount = new Decimal(lWithStrContractAmount.contractAmount);
  }

  // input from server will have createdAt as a string, we convert it to Date
  const lWithStrCreatedAt: { createdAt: string | Date } = l;
  if (typeof lWithStrCreatedAt.createdAt === 'string') {
    l.createdAt = parseISO(lWithStrCreatedAt.createdAt);
  }

  // input from server will have updatedAt as a string, we convert it to Date
  const lWithStrUpdatedAt: { updatedAt: string | Date } = l;
  if (typeof lWithStrUpdatedAt.updatedAt === 'string') {
    l.updatedAt = parseISO(lWithStrUpdatedAt.updatedAt);
  }

  // input from server will have createdAt as a string, we convert it to Date
  const lWithStrBuyinExecutionDate: { nextAllowedBuyInExecutionDate: string | Date | null } = l;
  if (typeof lWithStrBuyinExecutionDate.nextAllowedBuyInExecutionDate === 'string') {
    l.nextAllowedBuyInExecutionDate = parseISO(
      lWithStrBuyinExecutionDate.nextAllowedBuyInExecutionDate
    );
  }

  if (l.renegotiation !== null) {
    normalizeLoanRenegotiation(l.renegotiation);
  }

  if ('side' in l) {
    normalizeAvailableLoanActions(l);
  }
}

export interface LoanRenegotiation {
  id: string;
  side: RenegotiateSide;
  rate: Decimal;
  rateModifier: Benchmark;
}

export function normalizeLoanRenegotiation(lr: LoanRenegotiation): void {
  // input from server may have reneg rate as a string, we convert it to Decimal
  const lWithStrRenegRate: { rate: string | Decimal } = lr;
  if (typeof lWithStrRenegRate.rate === 'string') {
    lr.rate = new Decimal(lWithStrRenegRate.rate);
  }
}

export type RecallStatus = 'new' | 'approved' | 'made' | 'canceled' | 'pendingcancel';

export interface OpenRecall {
  status: RecallStatus;
  id: string;
  originalQuantity: number;
  returnedQuantity: number;
  buyInQuantity: number;
  openQuantity: number;
  allowedBuyInExecutionDate: Date;
  recallTime: Date;
}

export interface CancelRecallResponse {
  id: string;
  error?: string;
}

export function normalizeOpenRecalls(recalls: OpenRecall[]): void {
  recalls.forEach((r) => {
    // input from server will have allowedBuyInExecutionDate as a string, we convert it to Date
    const rWithStrAllowedBuyInExecutionDate: { allowedBuyInExecutionDate: string | Date } = r;
    if (typeof rWithStrAllowedBuyInExecutionDate.allowedBuyInExecutionDate === 'string') {
      r.allowedBuyInExecutionDate = parseISO(
        rWithStrAllowedBuyInExecutionDate.allowedBuyInExecutionDate
      );
    }

    // input from server will have recallTime as a string, we convert it to Date
    const rWithStrRecallTime: { recallTime: string | Date } = r;
    if (typeof rWithStrRecallTime.recallTime === 'string') {
      r.recallTime = parseISO(rWithStrRecallTime.recallTime);
    }
  });
}

export interface AggregatedLoansRequest {
  group: string | null;

  filters: {
    side: 'LENDER' | 'BORROWER' | null;
    counterpartyCompanyId: string | null;
    cusip: string | null;
  };

  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface AggregatedOpenLoansParams {
  group: Partial<AggregatedLoansRequest['group']>;
  filters?: Partial<AggregatedLoansRequest['filters']>;
  sort?: Partial<AggregatedLoansRequest['sort']>;
  pagination?: Partial<AggregatedLoansRequest['pagination']>;
}

export interface AggregatedLoans {
  items: AggregatedLoanBySecurityItem[] | AggregatedLoanByCounterpartyItem[];
  total: number;
}

interface AggregatedLoanItem {
  side: 'LENDER' | 'BORROWER';
  loansCount: number;
  totalValue: Decimal | string;
  totalOpenQty: number;
  totalRecallQty: number;
  totalPendingReturnQty: number;
  totalOpenQtyForReturn: number;
  avgRate: Decimal | string;
}

export interface AggregatedLoanBySecurityItem extends AggregatedLoanItem {
  ticker: string;
  cusip: string;
  counterpartyCount: number;
}

export interface AggregatedLoanByCounterpartyItem extends AggregatedLoanItem {
  counterpartyDisplay: string; // @TODO: remove me once it's removed from backend
  counterparty: CompanyInfo;
  securityCount: number;
}

export function normalizeAggregatedLoan(item: AggregatedLoanItem): void {
  const itemWithStrAvgRate: { avgRate: string | Decimal } = item;
  if (typeof itemWithStrAvgRate.avgRate === 'string') {
    item.avgRate = new Decimal(itemWithStrAvgRate.avgRate);
  }

  const itemWithStrTotalValue: { totalValue: string | Decimal } = item;
  if (typeof itemWithStrTotalValue.totalValue === 'string') {
    item.totalValue = new Decimal(itemWithStrTotalValue.totalValue);
  }
}

export type LoanEventType =
  | 'CREATED'
  | 'PENDING'
  | 'DO_PENDING'
  | 'MADE'
  | 'DROPPED'
  | 'REJECTED'
  | 'CANCEL_PENDING'
  | 'CANCEL_REJECTED'
  | 'CANCELED'
  | 'ROLL_PENDING'
  | 'ROLL_PD_PENDING'
  | 'ROLL_PD_MADE'
  | 'BUY_IN_PO_MADE'
  | 'BUY_IN_PO_FAILED'
  | 'ROLLED'
  | 'MARKED_TO_MARKET'
  | 'MARK_TO_MARKET_PO_MADE'
  | 'MARK_TO_MARKET_PO_FAILED'
  | 'DAILY_INTEREST_AMOUNT'
  | 'DAILY_INTEREST_PO_FAILED'
  | 'DAILY_INTEREST_PO_MADE'
  | 'RENEGOTIATED'
  | 'RENEGOTIATION_CANCELED'
  | 'RENEGOTIATION_REJECTED'
  | 'RENEGOTIATION_EXPIRED'
  | 'PARTIALLY_RETURNED'
  | 'RETURNED'
  | 'CANCEL_RETURN_PENDING'
  | 'RETURN_CANCELED'
  | 'RETURN_PENDING'
  | 'RETURN_FAILED'
  | 'RETURN_REJECTED'
  | 'TERMINATED'
  | 'RECALLED'
  | 'RECALL_REJECTED'
  | 'RECALL_CANCELED'
  | 'BUY-IN'
  | 'BUY-IN_PENDING'
  | 'BUY-IN_CANCELED'
  | 'BUY-IN_REJECTED'
  | 'BUY-IN_FAILED'
  | 'CORP-ACTION'
  | 'FLOATING_RATE_RECALCULATED';

export type LoanEvent =
  | LoanEventCreated
  | LoanEventDOPending
  | LoanEventPending
  | LoanEventMade
  | LoanEventDropped
  | LoanEventRejected
  | LoanEventCancelNewLoanPending
  | LoanEventCancelNewLoanRejected
  | LoanEventCanceled
  | LoanEventRecalled
  | LoanEventRecallRejected
  | LoanEventRecallCanceled
  | LoanEventRollPending
  | LoanEventRollPDPending
  | LoanEventRollPDMade
  | LoanEventRolled
  | LoanEventReturned
  | LoanEventCancelReturnPending
  | LoanEventReturnFailed
  | LoanEventReturnPending
  | LoanEventReturnCanceled
  | LoanEventReturnRejected
  | LoanEventTerminated
  | LoanEventBuyIn
  | LoanEventBuyInPending
  | LoanEventBuyInRejected
  | LoanEventBuyInCanceled
  | LoanEventRenegotiated
  | LoanEventRenegotiationExpired
  | LoanEventRenegotiationRejected
  | LoanEventRenegotiationCanceled
  | LoanEventCorpAction;

export interface LoanEventBase {
  eventType: LoanEventType;
  eventTimestamp: string;
  initiator: EventInitiator;
  loanState: {
    openQuantity: number;
    rate: Decimal;
    unitPrice: Decimal;
    settlementValue: Decimal;
    dailyInterestAmount: Decimal;
    pendingDtccRefId?: string;
    activeDtccRefId?: string;
  };
}

export function normalizeLoanEvent(ev: LoanEvent): void {
  ev.loanState.rate = ensureDecimal(ev.loanState.rate);
  ev.loanState.unitPrice = ensureDecimal(ev.loanState.unitPrice);
  ev.loanState.settlementValue = ensureDecimal(ev.loanState.settlementValue);
  ev.loanState.dailyInterestAmount = ensureDecimal(ev.loanState.dailyInterestAmount);

  switch (ev.eventType) {
    case 'ROLLED':
      ev.unitPrice = ensureDecimal(ev.unitPrice);
      break;

    case 'BUY-IN':
      ev.avgPricePerShare = ensureDecimal(ev.avgPricePerShare);
      break;

    case 'RENEGOTIATED':
      ev.oldRate = ensureDecimal(ev.oldRate);
      ev.newRate = ensureDecimal(ev.newRate);
      break;

    case 'CORP-ACTION':
      if (ev.instrumentChange) {
        normalizeEquity(ev.instrumentChange.oldInstrument);
        normalizeEquity(ev.instrumentChange.newInstrument);
      }
      break;
  }
}

export interface LoanEventCreated extends LoanEventBase {
  eventType: 'CREATED';
}

export interface LoanEventDOPending extends LoanEventBase {
  eventType: 'DO_PENDING';
}

export interface LoanEventPending extends LoanEventBase {
  eventType: 'PENDING';
}

export interface LoanEventMade extends LoanEventBase {
  eventType: 'MADE';
}

export interface LoanEventDropped extends LoanEventBase {
  eventType: 'DROPPED';
}

export interface LoanEventRejected extends LoanEventBase {
  eventType: 'REJECTED';
}

export interface LoanEventCancelNewLoanPending extends LoanEventBase {
  eventType: 'CANCEL_PENDING';
}

export interface LoanEventCancelNewLoanRejected extends LoanEventBase {
  eventType: 'CANCEL_REJECTED';
}

export interface LoanEventCanceled extends LoanEventBase {
  eventType: 'CANCELED';
}

export interface LoanEventRecalled extends LoanEventBase {
  eventType: 'RECALLED';
  recalledQuantity: number;
  totalRecalledQuantity: number;
}

export interface LoanEventRecallRejected extends LoanEventBase {
  eventType: 'RECALL_REJECTED';
  rejectedRecalledQuantity: number;
  totalRecalledQuantity: number;
}

export interface LoanEventRecallCanceled extends LoanEventBase {
  eventType: 'RECALL_CANCELED';
  canceledRecalledQuantity: number;
  totalRecalledQuantity: number;
}

export interface LoanEventRollPending extends LoanEventBase {
  eventType: 'ROLL_PENDING';
  rolledQuantity: number;
  unitPrice: Decimal;
}

export interface LoanEventRollPDPending extends LoanEventBase {
  eventType: 'ROLL_PD_PENDING';
  direction: Side;
  amount: Decimal;
}

export interface LoanEventRollPDMade extends LoanEventBase {
  eventType: 'ROLL_PD_MADE';
  direction: Side;
  amount: Decimal;
}

export interface LoanEventRolled extends LoanEventBase {
  eventType: 'ROLLED';
  rolledQuantity: number;
  unitPrice: Decimal;
}

export interface LoanEventReturned extends LoanEventBase {
  eventType: 'RETURNED';
  returnedQuantity: number;
  remainingQuantity: number;
}

export interface LoanEventCancelReturnPending extends LoanEventBase {
  eventType: 'CANCEL_RETURN_PENDING';
}

export interface LoanEventReturnPending extends LoanEventBase {
  eventType: 'RETURN_PENDING';
  quantity: number;
}

export interface LoanEventReturnCanceled extends LoanEventBase {
  eventType: 'RETURN_CANCELED';
  quantity: number;
}

export interface LoanEventReturnFailed extends LoanEventBase {
  eventType: 'RETURN_FAILED';
  failedQuantity: number;
}

export interface LoanEventReturnRejected extends LoanEventBase {
  eventType: 'RETURN_REJECTED';
  quantity: number;
}

export interface LoanEventTerminated extends LoanEventBase {
  eventType: 'TERMINATED';
}

export interface LoanEventBuyIn extends LoanEventBase {
  eventType: 'BUY-IN';
  avgPricePerShare: Decimal;
  buyInQuantity: number;
  remainingQuanity: number;
}

export interface LoanEventBuyInPending extends LoanEventBase {
  eventType: 'BUY-IN_PENDING';
  avgPricePerShare: Decimal;
  buyInQuantity: number;
  remainingQuanity: number;
}

export interface LoanEventBuyInRejected extends LoanEventBase {
  eventType: 'BUY-IN_REJECTED';
  quantity: number;
}

export interface LoanEventBuyInCanceled extends LoanEventBase {
  eventType: 'BUY-IN_CANCELED';
  quantity: number;
}

export interface LoanEventRenegotiated extends LoanEventBase {
  eventType: 'RENEGOTIATED';
  proposedBy: RenegotiateSide;
  oldRate: Decimal;
  newRate: Decimal;
}

export interface LoanEventRenegotiationCanceled extends LoanEventBase {
  eventType: 'RENEGOTIATION_CANCELED';
  proposedBy: RenegotiateSide;
  oldRate: Decimal;
  newRate: Decimal;
}

export interface LoanEventRenegotiationRejected extends LoanEventBase {
  eventType: 'RENEGOTIATION_REJECTED';
  proposedBy: RenegotiateSide;
  oldRate: Decimal;
  newRate: Decimal;
}

export interface LoanEventRenegotiationExpired extends LoanEventBase {
  eventType: 'RENEGOTIATION_EXPIRED';
  proposedBy: RenegotiateSide;
  oldRate: Decimal;
  newRate: Decimal;
}

export interface LoanEventCorpAction extends LoanEventBase {
  eventType: 'CORP-ACTION';
  corpActionType: string;
  instrumentChange: {
    oldInstrument: Equity;
    newInstrument: Equity;
  } | null;
  oldQuantity: number;
  newQuantity: number;
}

export type LoanDetailsLoan = (LenderOpenLoan | BorrowerOpenLoan) & {
  returnPendingSince: Date | null;
  renegotiatePendingSince: Date | null;
  pendingDtccRefId: string | null;
  activeDtccRefId: string | null;
  canceledReason: string;
};

export interface LoanDetails {
  history: LoanEvent[];
  loan: LoanDetailsLoan | null;
}

export function loanDetailsFromApiResponse(data: {
  items: LoanEvent[];
  summary: LoanDetailsLoan;
}): LoanDetails {
  const history = data.items;
  const loan = data.summary;

  if (loan.renegotiatePendingSince !== null) {
    loan.renegotiatePendingSince = ensureDate(loan.renegotiatePendingSince);
  }
  if (loan.returnPendingSince !== null) {
    loan.returnPendingSince = ensureDate(loan.returnPendingSince);
  }

  normalizeOpenLoan(loan, {});
  history.forEach((ev) => normalizeLoanEvent(ev));

  return { history, loan };
}

const colorNotImportant = 'grey';
const colorClosedNormal = 'red darken-4';
const colorClosedNotNormal = 'red darken-1';
const colorRoll = 'blue lighten-2';
const colorPO = `blue light-2`;
const colorPoFailed = `red light-2`;
const colorCancel = 'secondary';
const colorRequiresAttention = 'orange darken-3';
const colorRate = 'purple';

export const newLoanEvents: Partial<Record<LoanHistoryEventType, LoanEventType>> = {
  [LoanHistoryEventType.LHET_CREATED]: 'CREATED',
  [LoanHistoryEventType.LHET_PENDING]: 'PENDING',
  [LoanHistoryEventType.LHET_MADE]: 'MADE',
  [LoanHistoryEventType.LHET_DROPPED]: 'DROPPED',
  [LoanHistoryEventType.LHET_REJECTED]: 'REJECTED',
  [LoanHistoryEventType.LHET_CANCELLED]: 'CANCELED',
  [LoanHistoryEventType.LHET_RECALLED]: 'RECALLED',
  [LoanHistoryEventType.LHET_RECALL_CANCELED]: 'RECALL_CANCELED',
  [LoanHistoryEventType.LHET_ROLLED]: 'ROLLED',
  [LoanHistoryEventType.LHET_ROLL_PENDING]: 'ROLL_PENDING',
  [LoanHistoryEventType.LHET_RETURNED]: 'RETURNED',
  [LoanHistoryEventType.LHET_PARTIALLY_RETURNED]: 'PARTIALLY_RETURNED',
  [LoanHistoryEventType.LHET_RETURN_CANCELED]: 'RETURN_CANCELED',
  [LoanHistoryEventType.LHET_RETURN_FAILED]: 'RETURN_FAILED',
  [LoanHistoryEventType.LHET_RETURN_REJECTED]: 'RETURN_REJECTED',
  [LoanHistoryEventType.LHET_TERMINATED]: 'TERMINATED',
  [LoanHistoryEventType.LHET_BUY_IN]: 'BUY-IN',
  [LoanHistoryEventType.LHET_BUY_IN_CANCELLED]: 'BUY-IN_CANCELED',
  [LoanHistoryEventType.LHET_BUY_IN_REJECTED]: 'BUY-IN_REJECTED',
  [LoanHistoryEventType.LHET_RENEGOTIATED]: 'RENEGOTIATED',
  [LoanHistoryEventType.LHET_RENEGOTIATION_CANCELED]: 'RENEGOTIATION_CANCELED',
  [LoanHistoryEventType.LHET_RENEGOTIATION_REJECTED]: 'RENEGOTIATION_REJECTED',
  [LoanHistoryEventType.LHET_RENEGOTIATION_EXPIRED]: 'RENEGOTIATION_EXPIRED',
  [LoanHistoryEventType.LHET_CORPORATE_ACTION]: 'CORP-ACTION',
};

export const loanEvents: Record<LoanEventType, { color: string; label: string }> = {
  CREATED: { color: 'green', label: 'Created' },
  PENDING: { color: 'blue', label: 'Pending' },
  DO_PENDING: { color: colorNotImportant, label: 'DO Pending' },
  MADE: { color: colorNotImportant, label: 'Made' },
  DROPPED: { color: colorClosedNotNormal, label: 'Dropped' },
  REJECTED: { color: colorNotImportant, label: 'Rejected' },
  CANCEL_PENDING: { color: colorNotImportant, label: 'Cancel Pending' },
  CANCELED: { color: colorNotImportant, label: 'Canceled' },
  CANCEL_REJECTED: { color: colorPoFailed, label: 'Cancel Rejected' },
  ROLL_PENDING: { color: colorRoll, label: 'Roll Pending' },
  ROLL_PD_PENDING: { color: colorRoll, label: 'PD Pending' },
  ROLL_PD_MADE: { color: colorRoll, label: 'PD Made' },
  ROLLED: { color: 'blue', label: 'Rolled' },
  BUY_IN_PO_FAILED: { color: colorPoFailed, label: 'Buy-in PO Failed' },
  BUY_IN_PO_MADE: { color: colorPO, label: 'Buy-in PO Made' },
  MARK_TO_MARKET_PO_FAILED: { color: colorPoFailed, label: 'M-T-M PO Failed' },
  MARK_TO_MARKET_PO_MADE: { color: colorPO, label: 'M-T-M PO Made' },
  MARKED_TO_MARKET: { color: colorNotImportant, label: 'Marked' },
  DAILY_INTEREST_AMOUNT: { color: colorNotImportant, label: 'Daily Interest' },
  DAILY_INTEREST_PO_FAILED: { color: colorPoFailed, label: 'Daily Interest PO Failed' },
  DAILY_INTEREST_PO_MADE: { color: colorPO, label: 'Daily Interest PO Made' },
  RENEGOTIATED: { color: colorRate, label: 'Renegotiated' },
  RENEGOTIATION_CANCELED: { color: colorCancel, label: 'Renegotiation Canceled' },
  RENEGOTIATION_REJECTED: { color: colorRequiresAttention, label: 'Renegotiation Rejected' },
  RENEGOTIATION_EXPIRED: { color: colorNotImportant, label: 'Renegotiation Expired' },
  TERMINATED: { color: colorClosedNotNormal, label: 'Terminated' },
  PARTIALLY_RETURNED: { color: colorClosedNormal, label: 'Partially Returned' },
  RETURNED: { color: colorClosedNormal, label: 'Returned' },
  RETURN_PENDING: { color: colorRoll, label: 'Return Pending' },
  CANCEL_RETURN_PENDING: { color: colorRequiresAttention, label: 'Cancel Return Pending' },
  RETURN_CANCELED: { color: colorCancel, label: 'Return Canceled' },
  RETURN_FAILED: { color: colorNotImportant, label: 'Return Failed' },
  RETURN_REJECTED: { color: colorRequiresAttention, label: 'Return Rejected' },
  RECALLED: { color: colorClosedNotNormal, label: 'Recalled' },
  RECALL_REJECTED: { color: colorCancel, label: 'Recall Rejected' },
  RECALL_CANCELED: { color: colorCancel, label: 'Recall Canceled' },
  'BUY-IN': { color: colorClosedNotNormal, label: 'Buy-In' },
  'BUY-IN_PENDING': { color: colorNotImportant, label: 'Buy-In Pending' },
  'BUY-IN_CANCELED': { color: colorCancel, label: 'Buy-In Canceled' },
  'BUY-IN_REJECTED': { color: colorRequiresAttention, label: 'Buy-In Rejected' },
  'BUY-IN_FAILED': { color: colorPoFailed, label: 'Buy-In Failed' },
  'CORP-ACTION': { color: colorClosedNotNormal, label: 'Corp. Action' },
  FLOATING_RATE_RECALCULATED: { color: colorRate, label: 'Rate recalculated' },
};

/**
 * Injects "availableActions" into the object based on the current loan state
 */
export function normalizeAvailableLoanActions(loan: LenderOpenLoan | BorrowerOpenLoan): void {
  loan.availableActions = {};

  if (loan.status === 'NEW') {
    return;
  }

  // canceling bilateral loans not supported yet.
  if (loan.status === 'PENDING' && loan.settlementType != 'BILATERAL') {
    loan.availableActions.cancelPending = true;
    return;
  }

  // statuses different than OPEN have already been handled, we can return early
  if (loan.status !== 'OPEN') {
    return;
  }

  // only allow renegotiation if the loan was not generated by a term loan contract
  if (loan.termContractDisplayId === null) {
    // Renegotiate actions (both lender and borrower)
    if (loan.renegotiation === null) {
      if (loan.rateModifier === Benchmark.NoBenchmark) {
        loan.availableActions.renegotiateFixed = true;
      } else {
        loan.availableActions.renegotiateFloating = true;
      }
    } else if (
      (loan.side === 'LENDER' && loan.renegotiation.side === RenegotiateSide.Lender) ||
      (loan.side === 'BORROWER' && loan.renegotiation.side === RenegotiateSide.Borrower)
    ) {
      loan.availableActions.cancelRenegotiate = true;
    } else {
      loan.availableActions.acceptRenegotiate = true;
      loan.availableActions.rejectRenegotiate = true;
    }
  }

  // Lender actions
  if (loan.side === 'LENDER' || loan.sponsorshipSide === 'sponsor') {
    if (loan.recalledQuantity > 0 && loan.pendingBuyInQuantity < loan.recalledQuantity) {
      // If there's a buy-in in progress, only enable if it's less than the recalledQuantity
      loan.availableActions.updateRecall = true;
    } else if (loan.recalledQuantity === 0) {
      loan.availableActions.recall = true;
    }

    if (loan.openQuantityToBuyIn - loan.pendingBuyInQuantity - loan.pendingReturnQuantity > 0) {
      loan.availableActions.buyIn = true;
    }
  }

  // Borrower actions
  if (loan.side === 'BORROWER' || loan.sponsorshipSide === 'sponsor') {
    if (loan.pendingReturnQuantity > 0) {
      loan.availableActions.cancelReturn = true;
    }
    if (loan.openQuantityToReturn > 0) {
      if (loan.termContractDisplayId === null || loan.recalledQuantity > 0) {
        loan.availableActions.return = true;
      }
    }
  }
}
