import i18n from '@/localisation/i18n';
import { ColDef } from 'ag-grid-enterprise';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import ManualLoanStatusChip from '@/modules/manual-loan/components/ManualLoanStatusChip.vue';
import ManualLoansListTableActions from '@/modules/manual-loan/components/ManualLoansListTableActions.vue';
import { alignCenter, alignLeft, alignRight, component } from './utils';
import { h } from 'vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { ManualLoanListDisplayItem } from '@/modules/manual-loan/types/manual-loans';
export { checkbox, cusip, rate, settlementType, side, ticker } from './common';

const statusChipAdapter = component<{
  item: ManualLoanListDisplayItem;
}>(
  (props) => () =>
    h(ManualLoanStatusChip, {
      props: { item: props.item },
    })
);

export function status(): ColDef {
  return {
    field: 'status',
    colId: 'status',
    headerName: i18n.t('manualLoans.listCols.status') as string,
    cellRendererSelector: (params) => statusChipAdapter({ item: params.data }),
    resizable: false,
    pinned: 'left',
    width: 120,
    ...alignCenter(),
  };
}

// @TODO: we should stop using "counterpartyDisplay" and use "counterparty" instead
// because it's tightly coupled with the API response
export function counterparty(): ColDef {
  return {
    field: 'counterpartyDisplay',
    colId: 'counterpartyDisplay',
    headerName: i18n.t('manualLoans.listCols.counterparty') as string,
    ...alignLeft(),
  };
}

export const price = (): ColDef =>
  cols.price({
    field: 'price',
    headerName: i18n.t('manualLoans.listCols.price') as string,
  });

export const quantity = (): ColDef =>
  cols.quantity({
    field: 'quantity',
    headerName: i18n.t('manualLoans.listCols.qty') as string,
  });

export const contractAmount = (): ColDef =>
  cols.price({
    field: 'contractAmount',
    headerName: i18n.t('manualLoans.listCols.contractAmount') as string,
  });

export const settlementAmount = (): ColDef =>
  cols.price({
    field: 'settlementAmount',
    headerName: i18n.t('manualLoans.listCols.settlementAmount') as string,
  });

const independentAmountAdapter = component<{
  item: ManualLoanListDisplayItem;
  selectedTabName: 'incoming' | 'outgoing';
}>((props) => () => {
  if (props.selectedTabName === 'incoming' || props.item.side === 'LENDER') {
    return h(RateOutput, {
      props: {
        rate: props.item.independentAmountRate,
        precision: 2,
      },
    });
  }
  return h('span', 'N/A');
});

export function independentAmount({
  selectedTabName,
}: {
  selectedTabName: 'incoming' | 'outgoing';
}): ColDef {
  return {
    field: 'independentAmountRate',
    colId: 'independentAmountRate',
    headerName: i18n.t('manualLoans.listCols.independentAmountRate') as string,
    cellRendererSelector: (params) =>
      independentAmountAdapter({
        item: params.data as ManualLoanListDisplayItem,
        selectedTabName,
      }),
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

const actionsAdapter = component<{
  item: ManualLoanListDisplayItem;
  selectedTabName: 'incoming' | 'outgoing';
  accept: (item: ManualLoanListDisplayItem) => void;
  reject: (item: ManualLoanListDisplayItem) => void;
  cancel: (item: ManualLoanListDisplayItem) => void;
}>(
  (props) => () =>
    h(ManualLoansListTableActions, {
      props: {
        item: props.item,
        selectedTabName: props.selectedTabName,
      },
      on: {
        accept: props.accept,
        reject: props.reject,
        cancel: props.cancel,
      },
    })
);

export function actions({
  selectedTabName,
  accept,
  reject,
  cancel,
}: {
  selectedTabName: 'incoming' | 'outgoing';
  accept: (item: ManualLoanListDisplayItem) => void;
  reject: (item: ManualLoanListDisplayItem) => void;
  cancel: (item: ManualLoanListDisplayItem) => void;
}): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: i18n.t('manualLoans.listCols.actions') as string,
    cellRendererSelector: (params) =>
      actionsAdapter({
        item: params.data as ManualLoanListDisplayItem,
        selectedTabName,
        accept,
        reject,
        cancel,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
