import { EventInitiator } from '@/modules/common/types/api';
import {
  LoanHistoryEvent,
  LoanHistoryEventType,
} from '@/connect/gen/modules/apiengine/services/loans/loans_pb';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { Benchmark } from '@/connect/gen/consts/benchmarkconsts_pb';

const zero = new Decimal(0);

export interface Agg {
  sum: AggregateSum;
  avg: AggregateAvg;
  noun: AggregateNoun;
  selected: 'avg' | 'sum';
  nounOrQuantity: (name: string) => string;
}

interface AggregateSum {
  // total/avg quantities
  openQuantity: bigint;
  settlementAmount: Decimal;
  interestDueAmount: Decimal;
  unitPrice: Decimal;
}

interface AggregateAvg extends AggregateSum {
  // weighted avg rates
  rate: Decimal;
  rateModifiers: Benchmark[];
}

interface AggregateNoun {
  sides: Array<'LENDER' | 'BORROWER'>;
  parties: string[];
  settlementType: string[];
  ticker: string[];
  cusip: string[];
  eventTypes: LoanHistoryEventType[];
  initiators: EventInitiator[];
}

const initialSum: AggregateSum = {
  openQuantity: 0n,
  settlementAmount: zero,
  interestDueAmount: zero,
  unitPrice: zero,
};

const initialAvg: AggregateAvg = {
  ...initialSum,
  rate: zero,
  rateModifiers: [],
  unitPrice: zero,
};

const initialNoun: AggregateNoun = {
  sides: [],
  parties: [],
  settlementType: [],
  ticker: [],
  cusip: [],
  eventTypes: [],
  initiators: [],
};

// only push if item is not already in array
function pushIfUnique<T>(arr: T[], item: T) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

export function getAggHistory(historyItems: LoanHistoryEvent[], selected: 'sum' | 'avg'): Agg {
  const sum = cloneDeep(initialSum);
  const avg = cloneDeep(initialAvg);
  const noun = cloneDeep(initialNoun);

  let cntOpenQuantity = 0n;
  let sumRates = zero;

  historyItems.forEach((item: LoanHistoryEvent) => {
    sum.openQuantity += item.loanState!.openQuantity;
    sum.settlementAmount = sum.settlementAmount.add(item.loanState!.settlementAmount);
    sum.interestDueAmount = sum.interestDueAmount.add(item.loanState!.interestDueAmount);
    sum.unitPrice = sum.unitPrice.add(item.loanState!.unitPrice);

    cntOpenQuantity++;

    // pushIfUnique(noun.settlementType, item.loanState.settlementType);

    // loan can be either BrokerOpenLoan or OpenLoanItem
    // depending on that push the respective nouns
    // if ('side' in item.loan) {
    // const sideItem = proto3.getEnumType(Side).values.find((v) => v.no === item.loanState!.side);
    // if (sideItem) {
    //   pushIfUnique(noun.sides, sideItem.name === 'Borrower' ? 'borrower' : 'lender');
    // }
    pushIfUnique(noun.sides, item.loanState!.side === Side.BORROWER ? 'BORROWER' : 'LENDER');
    // @TODO: this is horrible and duplicates the logic we already have in the column definition
    const counterpartyDisplay = `${item.loanState!.counterparty!.companyName} (${item.loanState!.counterparty!.companyDisplayId})`;
    pushIfUnique(noun.parties, counterpartyDisplay);
    // } else {
    //   // broker loan
    // }
    pushIfUnique(noun.ticker, item.loanState!.instrument!.ticker);
    pushIfUnique(noun.cusip, item.loanState!.instrument!.cusip);
    pushIfUnique(avg.rateModifiers, item.loanState!.rateDetails?.benchmark);
    pushIfUnique(noun.eventTypes, item.eventType);
    // @TODO: we need to compare differently
    // pushIfUnique(noun.initiators, item.initiator);
    const rate = new Decimal(item.loanState!.rateDetails!.rate);
    sumRates = sumRates.add(rate.mul(Number(item.loanState!.openQuantity)));
  });

  avg.openQuantity = cntOpenQuantity
    ? sum.openQuantity / cntOpenQuantity +
      (sum.openQuantity % cntOpenQuantity >= cntOpenQuantity / 2n ? 1n : 0n)
    : 0n;

  avg.settlementAmount = cntOpenQuantity
    ? sum.settlementAmount.div(Number(cntOpenQuantity))
    : new Decimal(0);

  avg.interestDueAmount = cntOpenQuantity
    ? sum.interestDueAmount.div(Number(cntOpenQuantity))
    : new Decimal(0);

  avg.unitPrice = cntOpenQuantity ? sum.unitPrice.div(Number(cntOpenQuantity)) : new Decimal(0);

  // NOTE: we only display the average if the `rateModifier` is the same for all selected loans
  avg.rate = sum.openQuantity ? sumRates.div(Number(sum.openQuantity)) : zero;

  return {
    sum,
    avg,
    noun,
    selected,
    nounOrQuantity: (name: string) => {
      return noun[name].length === 1 ? noun[name][0] : `(${noun[name].length})`;
    },
  };
}
