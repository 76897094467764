import { ConnectResult, createPromiseClient } from '@/connect';
import { LoansService } from '@/connect/gen/modules/apiengine/services/loans/loans_connect';
import {
  LoanHistorySortableField,
  LoansHistoryQueryFilter,
  LoansHistoryQuerySort,
  QueryLoansHistoryResponse,
  SortOrder,
} from '@/connect/gen/modules/apiengine/services/loans/loans_pb';

// @TODO: remove or export something else?
export type * from '@/connect/gen/modules/apiengine/services/login/security_pb';

export const serviceLoans = new (class {
  public readonly promiseClient = createPromiseClient(LoansService);

  public async queryLoansHistory(config: {
    page: number;
    pageSize: number;
    sort: string;
    filter: LoansHistoryQueryFilter;
    signal: AbortSignal;
  }): Promise<ConnectResult<QueryLoansHistoryResponse>> {
    const sortFields: Record<string, LoanHistorySortableField> = {
      'loanState.openQuantity': LoanHistorySortableField.LHSF_OPEN_QUANTITY,
      'loanState.counterparty': LoanHistorySortableField.LHSF_COUNTERPARTY,
      'loanState.instrument.ticker': LoanHistorySortableField.LHSF_INSTRUMENT_TICKER,
      'loanState.instrument.cusip': LoanHistorySortableField.LHSF_INSTRUMENT_CUSIP,
      eventType: LoanHistorySortableField.LHSF_EVENT_TYPE,
      eventTime: LoanHistorySortableField.LHSF_EVENT_TIME,
    };

    const field = config.sort.replace('-', '');
    const order = config.sort.startsWith('-') ? SortOrder.SO_DESC : SortOrder.SO_ASC;
    const sorts =
      field in sortFields ? [new LoansHistoryQuerySort({ field: sortFields[field], order })] : [];
    const query = {
      filter: config.filter,
      sorts,
      pageNumber: config.page,
      pageLimit: config.pageSize,
    };
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(query, null, 4));
    const result = await this.promiseClient.queryLoansHistory(query);

    return result as ConnectResult<QueryLoansHistoryResponse>;
  }
})();
