<template>
  <span>
    <!-- icon button variant -->
    <aurora-btn
      v-if="icon"
      class="icon-action"
      :disabled="isLoading || !hasTraderUserRole"
      icon
      :loading="isLoading"
      timeframe="createLoans"
      x-small
      @click="toggle()"
    >
      <v-icon size="24">{{ routingStatus === 'ROUTED' ? 'mdi-reply' : 'mdi-share' }}</v-icon>
    </aurora-btn>

    <!-- regular button variant -->
    <aurora-btn
      v-else
      color="purple"
      :disabled="isLoading || routingStatus === 'pending' || !hasTraderUserRole"
      :loading="isLoading"
      timeframe="createLoans"
      @click="toggle()"
    >
      {{
        routingStatus === 'ROUTED'
          ? 'Deactivate'
          : routingStatus === 'PENDING'
            ? 'Pending'
            : 'Activate'
      }}
    </aurora-btn>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { Api } from '@/modules/common/types/api';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

@Component({
  props: {
    orderRef: String,
    routingStatus: {
      type: String as PropType<Api.Marketplace.Order['routingStatus']>,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['clientConfig']), //@TODO: remove?
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class MarketplaceToggleRouteBtn extends Vue {
  // props
  protected orderRef!: string;
  protected routingStatus!: Api.Marketplace.Order['routingStatus'];

  protected icon!: boolean;
  protected hasTraderUserRole!: boolean;

  // store state
  protected clientConfig!: ClientConfig;

  protected isLoading = false;

  protected async toggle(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.routingStatus === 'ROUTED') {
        await this.unroute();
      } else {
        await this.route();
      }
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.$emit('action', {
        error: true,
        orderRef: this.orderRef,
        message: `Operation failed: ${errorMessage}`,
      });
    } finally {
      this.isLoading = false;
    }
  }

  private async unroute(): Promise<void> {
    await this.$api.marketplace.unrouteOrder(this.orderRef);

    this.$emit('action', {
      orderRef: this.orderRef,
      message: 'Order has been successfully deactivated',
    });
  }

  private async route(): Promise<void> {
    await this.$api.marketplace.routeOrder(this.orderRef);

    this.$emit('action', {
      orderRef: this.orderRef,
      message: 'Order has been successfully activated',
    });
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}
</style>
