<template>
  <ag-table-client
    v-if="items.length"
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :is-row-selectable="isRowSelectable"
    :page-size="1000"
    :row-data="items"
    :selected-items="selectedItems"
    :sort="{ colId: 'createdAt', sort: 'desc' }"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { Api } from '@/modules/common/types/api';
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ColDef, GetRowIdParams, RowNode } from 'ag-grid-enterprise';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace';

const allActions = ['view', 'edit', 'route', 'unroute', 'cancel'];

const counterpartyChoiceEnabledCols = ['counterparties'];
const settlementTypeChoiceEnabledCols = ['settlementType'];

@Component({
  components: {
    AgTableClient,
  },
  props: {
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    actions: {
      type: Array as PropType<string[]>,
      default: () => allActions,
    },
    items: {
      type: Array as PropType<Api.Marketplace.OrdersResponse>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<Api.Marketplace.OrdersResponse>,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class MarketplaceOrdersTable extends Vue {
  // props
  protected readonly omitHeaders!: string[];
  protected readonly actions!: string[];
  protected readonly items!: Api.Marketplace.OrdersResponse;
  protected readonly selectedItems!: Api.Marketplace.Order[];
  protected readonly showSelect!: boolean;

  // store state
  protected clientConfig!: ClientConfig;

  // subset of items currently rendered in the table
  protected currentRenderedItems: Api.Marketplace.OrdersResponse = [];

  public get columnDefs(): ColDef[] {
    return this.allColumnDefs()
      .filter((colDef) => this.showSelect || colDef.colId !== 'checkbox')
      .filter((colDef) => !this.omitHeaders.includes(colDef.field as string))
      .filter(
        (colDef) =>
          this.clientConfig.orderbookCounterpartyChoiceEnabled ||
          !counterpartyChoiceEnabledCols.includes(colDef.field as string)
      )
      .filter(
        (colDef) =>
          this.clientConfig.orderbookSettlementTypeChoiceEnabled ||
          !settlementTypeChoiceEnabledCols.includes(colDef.field as string)
      );
  }

  public viewOrder(orderRef: string): void {
    this.$emit('view-order', orderRef);
  }

  protected allColumnDefs(): ColDef[] {
    return [
      cols.checkbox(),
      cols.status({
        viewOrder: this.viewOrder,
      }),
      cols.side(),
      cols.active(),
      cols.company(),
      cols.ticker(),
      cols.cusip(),
      cols.openQuantity(),
      cols.rate(),
      cols.execQty(),
      cols.avgExecRate(),
      cols.totalQuantity(),
      cols.createTime(),
      cols.orderType(),
      cols.timeInForce(),
      cols.minQuantity(),
      cols.counterparties(),
      cols.settlementType(),
      cols.orderRef(),
      cols.actions({
        actions: this.actions,
        viewOrder: this.viewOrder,
        editOrder: (order: Api.Marketplace.Order) => this.$emit('edit-order', order),
      }),
      // eliminate whitespace between columns
      // (we are already using auto-size-strategy = fitCellContents, so why??)
      // @TODO: review and find a better way? inject automatically? something else?
      {
        colId: 'spacer',
        flex: 1,
        suppressColumnsToolPanel: true,
      },
    ];
  }

  protected getRowId(params: GetRowIdParams<Api.Marketplace.Order>): string {
    return params.data.orderRef;
  }

  protected isRowSelectable(rowNode: RowNode): boolean {
    return !this.isStatusDisabled(rowNode.data.status);
  }

  // if the order status is one of the fixed array items return true
  // in the template the checkbox :disabled will be true
  protected isStatusDisabled(status: Api.Marketplace.Order['status']): boolean {
    const disabledStatuses: Array<Api.Marketplace.Order['status']> = [
      'CLOSED',
      'CANCELED',
      'FILLED',
      'EXPIRED',
      'TERMINATED',
    ];

    return disabledStatuses.includes(status);
  }

  protected filterOpenItems(items: Api.Marketplace.OrdersResponse): Api.Marketplace.OrdersResponse {
    return items.filter((item) => item.status !== 'FILLED' && item.status !== 'CANCELED');
  }
}
</script>
