<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (batch actions, filters, tabs) -->
    <v-container :class="[showTableFilters ? 'py-0' : 'pb-1']" fluid>
      <v-row no-gutters>
        <v-col
          :class="[
            'gap-1',
            'd-flex',
            'align-center',
            !showTableFilters && selectedItems.length > 0 ? 'pa-4' : '',
          ]"
          lg="6"
          md="12"
          sm="12"
          xl="6"
        >
          <slot name="header" />

          <!-- Displays number of selected items -->
          <template v-if="selectedItems.length">
            <span>
              {{ selectedItems.length ? `${selectedItems.length} of ${rowCount} selected` : '' }}
            </span>
          </template>

          <!-- All batch actions buttons and dialogs -->
          <open-loans-batch-actions
            v-if="selectedItems.length"
            :items="selectedItems"
            @success="
              resetSelection();
              selectedItems = [];
            "
          />
        </v-col>

        <v-col
          v-if="showTableFilters"
          class="gap-1 d-flex align-center"
          lg="6"
          md="12"
          sm="12"
          xl="6"
        >
          <!-- Filters -->
          <counterparty-search
            class="counterparties"
            clearable
            data-test="counterparty-search"
            include-sponsored
            placeholder="All counterparties"
            :side="counterpartySide"
            :value="counterparty"
            @change="$emit('update:counterparty', $event)"
          />
          <simple-equity-search
            class="simple-equity-search"
            clear-after-select
            clearable
            label="Security"
            :value="equity"
            @input="$emit('update:equity', $event)"
          />
          <v-select
            v-if="canBorrowAndLend"
            class="side"
            clearable
            :items="sideItems"
            label="Side"
            :value="side"
            @change="$emit('update:side', $event)"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-5x"
                  data-test="show-all-loans"
                  hide-details
                  :input-value="showAll"
                  label="show archived"
                  @change="$emit('update:showAll', $event)"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Positions that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row v-if="showTabs" class="mt-n2 mb-4" no-gutters>
        <!-- Tabs -->
        <v-tabs
          ref="tabs"
          v-model="selectedTabIndex"
          background-color="transparent"
          @change="resetSelection"
        >
          <v-tab v-for="tab in tabs" :key="tab.name" :value="tab.name">
            {{ tab.name }} ({{ tab.total }})
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <open-loans-table
      :class="[showTabs ? 'mt-0' : 'mt-n4']"
      data-test="open-loans-table"
      :omit-headers="omitHeaders"
      :page.sync="localPage"
      :page-size.sync="localPageSize"
      :query-data="queryData"
      :selected-items.sync="selectedItems"
      :show-select="showSelect"
      :sort.sync="localSort"
      @ready="onReady"
      @view-loan="handleViewLoan"
    />
  </v-card>
</template>

<script lang="ts">
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import OpenLoansBatchActions from '@/modules/open-loans/components/OpenLoansBatchActions.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import { OpenLoan, OpenLoansParams } from '@/utils/api/loans';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { LoadSuccessParams } from 'ag-grid-enterprise';

interface Tab {
  name: 'all' | 'recalled' | 'rerate';
  total: number;
}

function stubFn() {
  return;
}

@Component({
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    isRecalled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRenegotiating: {
      type: Boolean,
      default: false,
      required: false,
    },
    counterparty: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    equity: {
      type: null as unknown as PropType<Equity | null>,
      validator: (value: Equity | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    side: {
      type: null as unknown as PropType<'LENDER' | 'BORROWER' | null>,
      validator: (value: 'LENDER' | 'BORROWER' | null): boolean => {
        return value === null || value === 'LENDER' || value === 'BORROWER';
      },
      required: true,
    },
    showAll: {
      type: Boolean,
    },
    isSponsored: {
      // type acrobacy to allow the missing prop to be "undefined"
      // "type: Boolean" defaults" to "false" if the prop is missing
      type: undefined as unknown as PropType<boolean | undefined>,
      required: false,
    },
    sort: {
      type: String,
      required: true,
    },
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showTableFilters: {
      type: Boolean,
      default: true,
      required: false,
    },
    showTabs: {
      type: Boolean,
      default: true,
      required: false,
    },
    showSelect: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    CounterpartySearch,
    OpenLoansBatchActions,
    OpenLoansTable,
    SimpleEquitySearch,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'socketEvents']),
    ...mapGetters(['canBorrow', 'canBorrowAndLend']),
  },
})
export default class OpenLoansList extends Vue {
  // props
  protected readonly page!: number;
  protected readonly pageSize!: number;
  protected readonly omitHeaders!: string[];
  protected readonly isRecalled!: boolean;
  protected readonly isRenegotiating!: boolean;
  protected readonly counterparty!: CompanyInfo | null;
  protected readonly equity!: Equity | null;
  protected readonly side!: 'LENDER' | 'BORROWER' | null;
  protected readonly showAll!: boolean;
  protected readonly isSponsored?: boolean;
  protected readonly sort!: string;
  protected readonly showTableFilters!: boolean;
  protected readonly showTabs!: boolean;
  protected readonly showSelect!: boolean;

  // store state
  protected canBorrow!: boolean;
  protected canBorrowAndLend!: boolean;
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected socketEvents!: SocketEvents;

  protected totals = {
    all: 0,
    recalled: 0,
    rerate: 0,
  };
  protected selectedItems: OpenLoanItem[] = [];

  protected sideItems = [
    { text: 'Lender', value: 'LENDER' },
    { text: 'Borrower', value: 'BORROWER' },
  ];

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection: () => void = stubFn;

  protected get localPage(): number {
    return this.page;
  }

  protected set localPage(value: number) {
    this.$emit('update:page', value);
  }

  protected get localPageSize(): number {
    return this.pageSize;
  }

  protected set localPageSize(value: number) {
    this.$emit('update:page-size', value);
  }

  protected get localSort(): string {
    return this.sort;
  }

  protected set localSort(value: string) {
    this.$emit('update:sort', value);
  }

  protected get rowCount(): number {
    if (this.isRecalled) {
      return this.totals.recalled;
    } else if (this.isRenegotiating) {
      return this.totals.rerate;
    } else {
      return this.totals.all;
    }
  }

  protected get selectedTabIndex(): number {
    if (this.isRecalled) {
      return 1;
    } else if (this.isRenegotiating) {
      return 2;
    } else {
      return 0;
    }
  }

  protected set selectedTabIndex(newIndex: number) {
    // the tabs are nothing more than filters in the end
    // clearly not the best UI decision, with consequences like this switch
    const { name } = this.tabs[newIndex];
    switch (name) {
      case 'all':
        this.$emit('update:isRecalled', false);
        this.$emit('update:isRenegotiating', false);
        break;
      case 'recalled':
        this.$emit('update:isRecalled', true);
        this.$emit('update:isRenegotiating', false);
        break;
      case 'rerate':
        this.$emit('update:isRecalled', false);
        this.$emit('update:isRenegotiating', true);
        break;
    }
  }

  protected get tabs(): Tab[] {
    return [
      { name: 'all', total: this.totals.all },
      { name: 'recalled', total: this.totals.recalled },
      { name: 'rerate', total: this.totals.rerate },
    ];
  }

  protected get counterpartySide(): Side {
    return this.canBorrowAndLend ? 'ALL' : this.canBorrow ? 'LENDER' : 'BORROWER';
  }

  protected get isOrderbookEnabled(): boolean {
    return this.uxConfig.orderbookEnabledOverride !== null
      ? this.uxConfig.orderbookEnabledOverride
      : this.clientConfig.orderbookEnabled;
  }

  @Watch('isRecalled')
  @Watch('isRenegotiating')
  @Watch('equity')
  @Watch('counterparty')
  @Watch('side')
  @Watch('showAll')
  protected onChangeFilters(): void {
    this.resetSelection();
    this.selectedItems = [];
    // purge the cache to avoid slow UI animations
    this.tableRefresh({ purge: true });
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: string;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    try {
      const params: OpenLoansParams = {
        filters: {
          showAll: this.showAll,
          isRecalled: this.tabs[this.selectedTabIndex].name === 'recalled',
          isRenegotiating: this.tabs[this.selectedTabIndex].name === 'rerate',
          side: this.side,
          counterpartyCompanyId: this.counterparty ? this.counterparty.companyId : null,
          cusip: this.equity ? this.equity.cusip : null,
          isSponsored: this.isSponsored,
        },
        pagination: {
          page: config.page,
          limit: config.pageSize,
        },
        sort: config.sort,
      };

      const res = await this.$api.openLoans.fetchOpenLoans(params, config.signal);
      if (!res) return;

      this.totals.all = res.total;
      this.totals.recalled = res.recalledTotal;
      this.totals.rerate = res.rerateTotal;

      return { rowData: res.items, rowCount: this.rowCount };
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected handleViewLoan(
    detailLoan: OpenLoan | null,
    activeTab: 'history' | 'recalls' | 'corporateActions' = 'history'
  ): void {
    this.$emit('update:detailLoan', detailLoan);
    this.$emit('update:detailLoanInitialTab', activeTab);
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.counterparties {
  width: 8rem;
}

.simple-equity-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>
