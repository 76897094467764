import { NewsItem, WatchItem } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';

export const mockWatchItems: WatchItem[] = [
  {
    status: 'OPEN',
    security: 'Apple Inc.',
    ticker: 'AAPL',
    interest: 'sw-sl-rc',
    lastTrade: '30SEP 11:45',
    market: 'swaps',
    volume: 15000,
    rate: 0.25,
    duration: 'Open',
    auctionType: 'ABA',
  },
  {
    status: 'OPEN',
    security: 'Tesla',
    ticker: 'TSLA',
    interest: 'sw-sl-rc',
    lastTrade: '30SEP 11:45',
    market: 'swaps',
    volume: 15000,
    rate: 0.25,
    duration: 'Open',
    auctionType: 'ABA',
  },
  {
    status: 'OPEN',
    security: 'Tesla',
    ticker: 'TSLA',
    interest: 'sw-sl-rc',
    lastTrade: '30SEP 16:15',
    market: 'options',
    volume: 15000,
    rate: 0.65,
    duration: 'Open',
    auctionType: 'ABA',
  },
  {
    status: 'OPEN',
    security: 'Microsoft Inc.',
    ticker: 'MSFT',
    interest: 'sw-sl-rc',
    lastTrade: '30SEP 16:15',
    market: 'options',
    volume: 15000,
    rate: 0.65,
    duration: 'Open',
    auctionType: 'ABA',
  },
  {
    status: 'CLOSED',
    security: 'Facebook Inc.',
    ticker: 'FB',
    interest: 'sw-sl-rc',
    lastTrade: '29SEP 10:48',
    market: 'seclending',
    volume: 2000,
    rate: 0.24,
    duration: 'Closed',
    auctionType: 'ABA',
  },
  {
    status: 'CLOSED',
    security: 'Apple Inc.',
    ticker: 'AAPL',
    interest: 'sw-sl-rc',
    lastTrade: '29SEP 10:48',
    market: 'seclending',
    volume: 2000,
    rate: 0.24,
    duration: 'Closed',
    auctionType: 'ABA',
  },
  {
    status: 'CLOSED',
    security: 'Apple Inc.',
    ticker: 'AAPL',
    interest: 'sw-sl-rc',
    lastTrade: '29SEP 14:35',
    market: 'swaps',
    volume: 14000,
    rate: 0.26,
    duration: 'Closed',
    auctionType: 'ABA',
  },
  {
    status: 'CLOSED',
    security: 'Microsoft Inc.',
    ticker: 'MSFT',
    interest: 'sw-sl-rc',
    lastTrade: '29SEP 14:35',
    market: 'swaps',
    volume: 14000,
    rate: 0.88,
    duration: 'Closed',
    auctionType: 'ABA',
  },
  {
    status: 'CLOSED',
    security: 'Microsoft Inc.',
    ticker: 'MSFT',
    interest: 'sw-sl-rc',
    lastTrade: '28SEP 12:25',
    market: 'options',
    volume: 11000,
    rate: 105.11,
    duration: 'Closed',
    auctionType: 'ABA',
  },
];

export const mockMarketNewsItems: NewsItem[] = [
  {
    key: 'DOW',
    title: 'DOW',
    value: new Decimal(31000),
    change: new Decimal(Math.random() * 1000 - 500),
  },
  {
    key: 'S&P 500',
    title: 'S&P 500',
    value: new Decimal(3800),
    change: new Decimal(Math.random() * 200 - 100),
  },
  {
    key: 'Nasdaq',
    title: 'Nasdaq',
    value: new Decimal(13500),
    change: new Decimal(Math.random() * 300 - 150),
  },
  {
    key: 'Stoxx 50',
    title: 'Stoxx 50',
    value: new Decimal(3600),
    change: new Decimal(Math.random() * 50 - 25),
  },
  {
    key: 'DAX',
    title: 'DAX',
    value: new Decimal(14000),
    change: new Decimal(Math.random() * 100 - 50),
  },
  {
    key: 'Nikkei',
    title: 'Nikkei',
    value: new Decimal(28750),
    change: new Decimal(Math.random() * 200 - 100),
  },
];

export const mockSecurityNewsItems: NewsItem[] = [
  {
    key: 'AAPL',
    title: 'AAPL',
    value: new Decimal(104),
    change: new Decimal(Math.random() * 20 - 10),
  },
  {
    key: 'IBM',
    title: 'IBM',
    value: new Decimal(54),
    change: new Decimal(Math.random() * 5 - 3),
  },
  {
    key: 'GME',
    title: 'GME',
    value: new Decimal(8),
    change: new Decimal(Math.random() * 2 - 1),
  },
];
