<template>
  <!-- template must have 1 direct child, we wrap the contents in a <div>
       with "display: contents", making sure layout rendering is not affected -->
  <div style="display: contents">
    <slot
      v-bind="{
        autoSizeStrategy: { type: 'fitCellContents' },
        theme: 'ag-theme-material-dark',
        headerHeight: 32,
        rowHeight: 32,
        rowSelection: 'multiple',
        style: 'width: 100%; height: 100%;',
      }"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class AgTableStyles extends Vue {}
</script>

<style lang="scss" scoped>
::v-deep {
  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-root-wrapper {
    --ag-header-cell-hover-background-color: transparent;
    --ag-header-background-color: transparent;
    --ag-footer-background-color: transparent;
    --ag-background-color: transparent;
    --ag-odd-row-background-color: transparent;
    --ag-checkbox-checked-color: #fff;
    --ag-checkbox-unchecked-color: #fff;
    --ag-checkbox-background-color: transparent;
    --ag-material-accent-color: #fff;
    --ag-range-selection-border-color: #fff;
    --ag-selected-row-background-color: #505050;
    --ag-row-hover-color: #616161;
    --ag-cell-horizontal-padding: 1rem;
    --scrollbarBG: #282828;

    .ag-floating-bottom {
      background-color: #141414;
    }

    .ag-header-cell {
      /* ignore checkbox header */
      &:not(:has(.ag-header-select-all:not(.ag-hidden))) {
        --ag-cell-horizontal-padding: 0.5rem !important;
      }

      .ag-cell-label-container {
        flex-direction: row;

        .ag-sort-indicator-container {
          /**
           * hide container for sorting indicator that has no visible children
           * in order to exclude it from flex flow and remove useless gap
           */
          &:not(:has(.ag-sort-indicator-icon:not(.ag-hidden))) {
            display: none;
          }

          & > * {
            padding: 0;
          }
        }

        &.ag-header-cell-sorted-none:not(:hover) {
          opacity: 0.7;
        }

        .ag-header-cell-label {
          gap: 0.5rem;
          padding-left: 0.5rem;
        }
      }

      &.header-center .ag-header-cell-label {
        justify-content: flex-center;
      }

      &.header-right .ag-header-cell-label {
        justify-content: flex-end;
      }
    }

    .ag-cell {
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .v-text-field {
      margin: 0;
    }
  }
}
</style>
