<template>
  <v-card class="flex d-flex flex-column fill-height">
    <v-card-title class="mb-4">
      <span class="headline">{{ $t('adminCounterpartyCredits.title') }}</span>
    </v-card-title>
    <v-card-subtitle>
      <span class="text--secondary headline-2">
        {{ $t('adminCounterpartyCredits.description') }}
      </span>
    </v-card-subtitle>
    <v-card-text v-if="isLoading" class="text--primary">
      <v-container class="flex-column justify-center fill-height">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
        <h3 class="mt-4">Loading credits ...</h3>
      </v-container>
    </v-card-text>
    <template v-else>
      <ag-table-client
        :column-defs="columnDefs"
        :get-row-id="getRowId"
        :page-size="1000"
        :row-data="counterpartyCredits"
        :selected-items.sync="selectedItems"
        :sort="{ colId: 'totalInSFT', sort: 'desc' }"
        :summary="[totals]"
      />
    </template>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import Decimal from 'decimal.js';
import { Watch } from 'vue-property-decorator';
import { SocketEvents } from '@/store/store';
import { throttle } from 'lodash';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/counterparty-credit';
import { ColDef, GetRowIdParams } from 'ag-grid-enterprise';
import { CounterpartyCredit } from '@/utils/api/credits';

const zero = new Decimal(0);

interface Totals {
  totalBilateral: Decimal;
  totalInSFT: Decimal;
}

export function newTotals(): Totals {
  return {
    totalBilateral: zero,
    totalInSFT: zero,
  };
}

@Component({
  components: {
    AgTableClient,
  },
  methods: {
    ...mapActions(['fetchTraderAdminCounterpartyCredits']),
  },
  computed: {
    ...mapState(['counterpartyCredits', 'socketEvents']),
  },
})
export default class TraderAdminCounterpartyCredit extends Vue {
  // store state
  protected readonly counterpartyCredits!: CounterpartyCredit[];
  protected readonly socketEvents!: SocketEvents;

  // store actions
  protected fetchTraderAdminCounterpartyCredits!: () => Promise<void>;

  protected selectedItems: CounterpartyCredit[] = [];
  protected selectAll!: () => void;
  protected isLoading = true;
  protected throttledFetch = throttle(this.fetch, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get totalBilateral(): Decimal {
    return this.totals.totalBilateral;
  }

  protected get totalSFTs(): Decimal {
    return this.totals.totalInSFT;
  }

  protected get grandTotal(): Decimal {
    return this.totals.totalBilateral.add(this.totals.totalInSFT);
  }

  protected get totals(): Totals {
    const t = newTotals();

    this.counterpartyCredits.forEach((counterparty) => {
      t.totalBilateral = t.totalBilateral.add(counterparty.totalBilateral);
      t.totalInSFT = t.totalInSFT.add(counterparty.totalInSFT);
    });

    return t;
  }

  protected get columnDefs(): ColDef[] {
    return this.allColumnDefs();
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  @Watch('socketEvents.openLoans.brokerLoan')
  protected onSocketEvents(): void {
    void this.throttledFetch();
  }

  protected allColumnDefs(): ColDef[] {
    return [
      cols.companyDisplay(),
      // cols.totalBilateral(),
      cols.totalInSFT(),
      // cols.grandTotal(),

      // eliminate whitespace between columns
      // (we are already using auto-size-strategy = fitCellContents, so why??)
      // @TODO: review and find a better way? inject automatically? something else?
      {
        colId: 'spacer',
        flex: 1,
      },
    ];
  }

  protected getRowId(params: GetRowIdParams<CounterpartyCredit>): string {
    return params.data.companyId;
  }

  protected async fetch(): Promise<void> {
    this.isLoading = true;
    try {
      await this.fetchTraderAdminCounterpartyCredits();
    } catch (e) {
      this.$log.warn(e);
    } finally {
      this.isLoading = false;
    }
  }

  protected mounted(): void {
    void this.fetch();
  }
}
</script>

<style lang="scss" scoped></style>
